import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { theme } from 'src/data/theme';
import { FooterService } from '../../footer.service';

export interface SocialLinksItem {
    type: string;
    url: string;
    icon: string;
}

export type SocialLinksShape = 'circle' | 'rounded';


@Component({
    selector: 'app-footer-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

    constructor(private http: HttpClient, private loderService: LoaderService
        , public footerService: FooterService) { }
    ngOnInit(): void {

    }
    SocialLinksShape = "circle";

    @HostBinding('class.social-links') classSocialLinks = true;

    @HostBinding('class.social-links--shape--circle') get classSocialLinksShapeCircle(): boolean { return this.SocialLinksShape === 'circle'; }

    @HostBinding('class.social-links--shape--rounded') get classSocialLinksShapeRounded(): boolean { return this.SocialLinksShape === 'rounded'; }
}
