import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Product } from '../../interfaces/product';
import { RootService } from '../../services/root.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
    debounceTime,
    map,
    switchMap,
    takeUntil,
    throttleTime,
} from 'rxjs/operators';
import { fromEvent, of, Subject, asyncScheduler } from 'rxjs';
import { ShopService } from '../../api/shop.service';
import { Category } from '../../interfaces/category';
import { DOCUMENT } from '@angular/common';
import { CartService } from '../../services/cart.service';
import { LoaderService } from '../../services/loader.service';
import { CompaniesService } from 'src/app/modules/companies/companies.service';
import { Router } from '@angular/router';
import { HtmlTagDefinition } from '@angular/compiler';

export type SearchLocation = 'header' | 'indicator' | 'mobile-header';

export type CategoryWithDepth = Category & { depth: number };

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    exportAs: 'search',
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    form: FormGroup;

    hasSuggestions = false;

    categories: CategoryWithDepth[] = [];

    suggestedProducts: Product[] = [];

    addedToCartProducts: Product[] = [];

    @Input() location: SearchLocation;

    @Output() escape: EventEmitter<void> = new EventEmitter<void>();

    @Output() closeButtonClick: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class.search') classSearch = true;

    @HostBinding('class.search--location--header')
    get classSearchLocationHeader(): boolean {
        return this.location === 'header';
    }

    @HostBinding('class.search--location--indicator')
    get classSearchLocationIndicator(): boolean {
        return this.location === 'indicator';
    }

    @HostBinding('class.search--location--mobile-header')
    get classSearchLocationMobileHeader(): boolean {
        return this.location === 'mobile-header';
    }

    @HostBinding('class.search--has-suggestions')
    get classSearchHasSuggestions(): boolean {
        return this.hasSuggestions;
    }

    @HostBinding('class.search--suggestions-open') classSearchSuggestionsOpen =
        false;

    @ViewChild('input') inputElementRef: ElementRef;

    get element(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    get inputElement(): HTMLElement {
        return this.inputElementRef.nativeElement;
    }

    categoriesNow: any[] = [];

    type:any;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private fb: FormBuilder,
        private elementRef: ElementRef,
        private zone: NgZone,
        private shop: ShopService,
        private cart: CartService,
        public root: RootService,
        private loderService: LoaderService,
        private companiesService: CompaniesService,
        private router:Router
    ) {}

    getCategories() {
        /*** get dynamic categories ***/
        let filterData = {
            id: null,
            businessType: 3,
            level: 3,
            isEagerLoaded: true,
        };
        this.companiesService.getCategoriesByBusinessType(filterData).subscribe(
            (data) => {
                this.loderService.setIsLoading = true;
                data.result.productsCategoryItem.map((item) => {
                    this.categoriesNow.push({ slug: item.slug, id: item.id, name: item.name, type: 1 });
                    item.categories.map((item2) => {
                        this.categoriesNow.push({
                            slug: item2.slug,
                            id: item2.id,
                            name: '&nbsp;&nbsp;&nbsp;&nbsp;' + item2.name,
                            type: 1
                        });
                        item2.categories.map((item3) => {
                            this.categoriesNow.push({
                                slug: item3.slug,
                                id: item3.id,
                                name: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + item3.name,
                                type: 1
                            });
                        });
                    });
                });
                data.result.servicesCategoryItem.map((item) => {
                    this.categoriesNow.push({ slug: item.slug, id: item.id, name: item.name, type: 2 });
                    item.categories.map((item2) => {
                        this.categoriesNow.push({
                            slug: item2.slug,
                            id: item2.id,
                            name: '&nbsp;&nbsp;&nbsp;&nbsp;' + item2.name,
                            type: 2
                        });
                        item2.categories.map((item3) => {
                            this.categoriesNow.push({
                                slug: item3.slug,
                                id: item3.id,
                                name: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + item3.name,
                                type: 2
                            });
                        });
                    });
                });
                this.loderService.setIsLoading = false;
            },
            (error) => {
                this.loderService.setIsLoading = false;
            }
        );
        /*** end get dynamic categories ***/
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.location && this.location === 'header') {
            this.shop
                .getCategories(null, 1)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    (categories) =>
                        (this.categories =
                            this.getCategoriesWithDepth(categories))
                );
        }
    }

    ngOnInit(): void {
        this.getCategories();
        this.form = this.fb.group({
            category: [''],
            query: [''],
        });

        this.form
            .get('query')
            .valueChanges.pipe(
                throttleTime(250, asyncScheduler, {
                    leading: true,
                    trailing: true,
                }),
                map((query) => query.trim()),
                switchMap((query) => {
                    if (query) {
                        const categorySlug =
                            this.form.value.category !== 'all'
                                ? this.form.value.category
                                : null;

                        return this.shop.getSuggestions(query, 5, categorySlug);
                    }

                    return of([]);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((products) => {
                this.hasSuggestions = products.length > 0;

                if (products.length > 0) {
                    this.suggestedProducts = products;
                }
            });

        this.zone.runOutsideAngular(() => {
            fromEvent(this.document, 'click')
                .pipe(takeUntil(this.destroy$))
                .subscribe((event) => {
                    const activeElement = this.document.activeElement;

                    // If the inner element still has focus, ignore the click.
                    if (
                        activeElement &&
                        activeElement.closest('.search') === this.element
                    ) {
                        return;
                    }

                    // Close suggestion if click performed outside of component.
                    if (
                        event.target instanceof HTMLElement &&
                        this.element !== event.target.closest('.search')
                    ) {
                        this.zone.run(() => this.closeSuggestion());
                    }
                });

            fromEvent(this.element, 'focusout')
                .pipe(debounceTime(10), takeUntil(this.destroy$))
                .subscribe(() => {
                    if (this.document.activeElement === this.document.body) {
                        return;
                    }

                    // Close suggestions if the focus received an external element.
                    if (
                        this.document.activeElement &&
                        this.document.activeElement.closest('.search') !==
                            this.element
                    ) {
                        this.zone.run(() => this.closeSuggestion());
                    }
                });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openSuggestion(): void {
        this.classSearchSuggestionsOpen = true;
    }

    closeSuggestion(): void {
        this.classSearchSuggestionsOpen = false;
    }

    getCategoryName(category: CategoryWithDepth): string {
        return '&nbsp;'.repeat(category.depth * 4) + category.name;
    }

    addToCart(product: Product): void {
        if (this.addedToCartProducts.includes(product)) {
            return;
        }

        this.addedToCartProducts.push(product);
        this.cart.add(product, 1).subscribe({
            complete: () => {
                this.addedToCartProducts = this.addedToCartProducts.filter(
                    (eachProduct) => eachProduct !== product
                );
            },
        });
    }

    private getCategoriesWithDepth(
        categories: Category[],
        depth = 0
    ): CategoryWithDepth[] {
        return categories.reduce<CategoryWithDepth[]>(
            (acc, category) => [
                ...acc,
                { ...category, depth },
                ...this.getCategoriesWithDepth(
                    category.children || [],
                    depth + 1
                ),
            ],
            []
        );
    }

    getCatName(slug) {
        let name = this.categoriesNow.find(item => item.slug === slug)?.name
        return this.replaceAll(name,'&nbsp;','');
    }

    replaceAll(string, search, replace) {
        if(!string) {
            return '';
        }
        else if(string && !string.includes('&nbsp;')) {
            return string;
        }
        else {
            return string.split(search).join(replace);
        }
	}

    changeType() {
        if(this.form.controls.category.value) {
            this.type = this.categoriesNow.find(item => item.slug === this.form.controls.category.value).type;
        }
        else {
            this.type = null;
        }
    }

    search() {
        if(this.type === 1) {
            if(!window.location.href.includes('offers')) {
                this.router.navigate(['/products'], { queryParams: { categorySlug: this.form.controls.category.value, categoryName: this.getCatName(this.form.controls.category.value), searchText: this.form.controls.query.value }, queryParamsHandling: 'merge' });
            }
            else {
                this.router.navigate(['/products/offers/list'], { queryParams: { categorySlug: this.form.controls.category.value, categoryName: this.getCatName(this.form.controls.category.value), searchText: this.form.controls.query.value }, queryParamsHandling: 'merge' });
            }
        }
        else if(this.type === 2) {
            if(!window.location.href.includes('offers')) {
                this.router.navigate(['/services'], { queryParams: { categorySlug: this.form.controls.category.value, categoryName: this.getCatName(this.form.controls.category.value), searchText: this.form.controls.query.value }, queryParamsHandling: 'merge' });
            }
            else {
                this.router.navigate(['/services/offers/list'], { queryParams: { categorySlug: this.form.controls.category.value, categoryName: this.getCatName(this.form.controls.category.value), searchText: this.form.controls.query.value }, queryParamsHandling: 'merge' });
            }
        }
        else {
            if(!window.location.href.includes('/products') && !window.location.href.includes('/services')) {
                this.router.navigate(['/products'], { queryParams: { categorySlug: this.form.controls.category.value, categoryName: this.getCatName(this.form.controls.category.value), searchText: this.form.controls.query.value }, queryParamsHandling: 'merge' });
            }
            else if(window.location.href.includes('/products') && !window.location.href.includes('/offers')) {
                this.router.navigate(['/products'], { queryParams: { categorySlug: this.form.controls.category.value, categoryName: this.getCatName(this.form.controls.category.value), searchText: this.form.controls.query.value }, queryParamsHandling: 'merge' });
            }
            else if(window.location.href.includes('/offers')) {
                this.router.navigate(['/products/offers/list'], { queryParams: { categorySlug: this.form.controls.category.value, categoryName: this.getCatName(this.form.controls.category.value), searchText: this.form.controls.query.value }, queryParamsHandling: 'merge' });
            }
            else if(window.location.href.includes('/services')) {
                this.router.navigate(['/services'], { queryParams: { categorySlug: this.form.controls.category.value, categoryName: this.getCatName(this.form.controls.category.value), searchText: this.form.controls.query.value }, queryParamsHandling: 'merge' });
            }
        }
    }
}
