<!-- .topbar -->
<div class="site-header__topbar topbar">
    <div class="topbar__container container">
        <div class="topbar__row">
            <!-- <div class="topbar__item topbar__item--link">
                <a class="topbar-link" routerLink="/site/about-us">{{'LINKS.ABOUTUS' | translate}}</a>
            </div>
            <div class="topbar__item topbar__item--link">
                <a class="topbar-link" routerLink="/site/contact-us">{{'LINKS.CONTACTUS' | translate}}</a>
            </div> -->
            <!-- <div class="topbar__item topbar__item--link">
                <a class="topbar-link" routerLink="">Store Location</a>
            </div>
            <div class="topbar__item topbar__item--link">
                <a class="topbar-link" routerLink="/shop/track-order">Track Order</a>
            </div>
            <div class="topbar__item topbar__item--link">
                <a class="topbar-link" routerLink="/blog">Blog</a>
            </div> -->
            <div class="topbar__spring"></div>
            <!-- <div class="topbar__item" *ngIf="checkLogin()">
                <div class="topbar-dropdown" appDropdown="topbar-dropdown--opened" #accountDropdown="appDropdown">
                    <button class="topbar-dropdown__btn" type="button" (click)="accountDropdown.toggle()">
                        {{'LINKS.MY_ACCOUNT' | translate}}
                        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                    </button>

                    <div class="topbar-dropdown__body">
                        <app-header-menu layout="topbar" (itemClick)="accountDropdown.close()" [items]="[
                            {label: 'LINKS.MY_ACCOUNT' | translate, url: 'auth/profile'},
                            {label: 'LINKS.OLD_ORDERS' | translate, url: '/auth/orders/old'},
                            {label: 'LINKS.ORDER_HISTORY' | translate, url: '/auth/orders'},
                            {label: 'LINKS.MY_RFQ' | translate,     url: '/rfq'},
                            {label: 'LINKS.ADDRESSES' | translate,     url: '/auth/addresses'},
                            {label: 'LINKS.PASSWORD' | translate,      url: '/auth/password'}
                        ]"></app-header-menu>
                    </div>
                </div>
            </div> -->
            <!-- <div class="topbar__item">
                <div class="topbar-dropdown" appDropdown="topbar-dropdown--opened" #currencyDropdown="appDropdown">
                    <button class="topbar-dropdown__btn" type="button" (click)="currencyDropdown.toggle()">
                        Currency: <span class="topbar__item-value">{{ selectedCurrency?.name || 'All'}}</span>
                        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                    </button>

                    <div class="topbar-dropdown__body">
                        <div class="menu menu--layout--topbar">
                            <ul class="menu__list">
                                <li
                                    class="menu__item"
                                    *ngFor="let currency of currencies"
                                    (mouseover)="menuItem.classList.add('menu__item--hover')"
                                    (mouseleave)="menuItem.classList.remove('menu__item--hover')"
                                    #menuItem
                                >
                                    <button class="menu__item-link" type="button" (click)="setCurrency(currency); currencyDropdown.close();">
                                        {{ currency.name }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="topbar__item">
                <div class="topbar-dropdown" appDropdown="topbar-dropdown--opened" #languageDropdown="appDropdown">
                    <button class="topbar-dropdown__btn" type="button" (click)="languageDropdown.toggle()">
                        {{'HEADER.LANGUAGE' | translate}}: <span class="topbar__item-value">{{getActiveLang().name}}</span>
                        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                    </button>

                    <div class="topbar-dropdown__body">
                        <div class="menu menu--layout--topbar menu--with-icons">
                            <ul class="menu__list">
                                <li
                                    class="menu__item"
                                    *ngFor="let language of languages"
                                    (mouseover)="menuItem.classList.add('menu__item--hover')"
                                    (mouseleave)="menuItem.classList.remove('menu__item--hover')"
                                    #menuItem
                                >
                                    <button class="menu__item-link" type="button" (click)="setLanguageWithRefresh(language.lang);languageDropdown.close();">
                                        <span class="menu__item-icon">
                                            <img class="flag" [src]="language.flag" alt="language">
                                        </span>
                                        {{ language.name }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .topbar / end -->
