<div class="mobilemenu" [ngClass]="{'mobilemenu--open': isOpen}">
    <div class="mobilemenu__backdrop" (click)="mobilemenu.close()"></div>
    <div class="mobilemenu__body">
        <div class="mobilemenu__header">
            <div class="mobilemenu__title">{{'TITLES.MENU' | translate}}</div>
            <button type="button" class="mobilemenu__close" (click)="mobilemenu.close()">
                <app-icon name="cross-20" size="20"></app-icon>
            </button>
        </div>
        <div class="mobilemenu__content">
            <app-mobile-links [links]="links" (itemClick)="onItemClick($event)"></app-mobile-links>
        </div>
        <div class="mobilemenu__footer">
            <a *ngIf="!checkIfLogin()" (click)="mobilemenu.close()" routerLink="/auth/login" class="btn btn-primary w-100">{{'LINKS.LOGIN' | translate}}</a>
            <button *ngIf="checkIfLogin()" (click)="logout();mobilemenu.close()" class="btn btn-primary w-100">{{'LINKS.LOGOUT' | translate}}</button>
        </div>
    </div>
</div>
