<div class="footer-newsletter">
    <h5 class="footer-newsletter__title">{{'TITLES.SOCIAL' | translate}}</h5>

    <div class="footer-newsletter__text footer-newsletter__text--social mb-3">
        {{'HINTS.FOLLOW_US_ON_SOCIAL' | translate}}
    </div>

    <!-- <app-social-links class="footer-newsletter__social-links" shape="circle"></app-social-links> -->
    <ng-container class="site-footer__widget" >
        <ul class="social-links__list ">
            <li *ngFor="let item of footerService.items" class="social-links__item">
                <a class="social-links__link social-links__link--type--{{ item.type }}" target="_blank"
                    [href]="item.url">
                    <i [ngClass]="item.icon"></i>
                </a>
            </li>
        </ul>
    </ng-container>
</div>