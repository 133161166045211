import { Component, Inject, Input, Output, PLATFORM_ID, EventEmitter } from '@angular/core';
import { Product } from '../../interfaces/product';
import { FormControl } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { RootService } from '../../services/root.service';
import { LoaderService } from '../../services/loader.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent {
    @Output() hideModalVal: EventEmitter<any> = new EventEmitter();
    @Input() layout: ProductLayout;

    @Input() product: Product;

    quantity: FormControl = new FormControl(1);

    addingToCart = false;
    addingToRfq = false;
    addingToWishlist = false;
    addingToCompare = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private wishlist: WishlistService,
        private compare: CompareService,
        public root: RootService,
        private loderService: LoaderService,
        private router: Router,
		public toaster: ToastrService,
        public translate: TranslateModule
    ) { }

    hideModal() {
        this.hideModalVal.emit('');
    }

    addToCart(): void {
		let data = {
			cartItem: {productId: this.product.id, quantity:this.quantity.value}
		};

		if(!localStorage.getItem('token')) {
            this.router.navigate(['auth/login'], {state: data});
            return;
        };
        if (!this.addingToCart && this.product && this.quantity.value > 0) {
            this.addingToCart = true;

            this.cart.addToCustomerCart(data).subscribe((data) => {
				this.addingToCart = false;
				this.toaster.success(data.result);
				this.cart.getCustomerCartCount();
			}, (error) => {
				this.addingToCart = false;
			});
        }
    }

    addRfq(): void {
        let data = {
			rfqItem: {productId: this.product.id, quantity:this.quantity.value}
		};

		if(!localStorage.getItem('token')) {
            this.router.navigate(['auth/login'], {state: data});
            return;
        };
        if (!this.addingToRfq && this.product) {
            this.addingToRfq = true;

            this.cart.addRfq(data).subscribe((data) => {
				this.addingToRfq = false
                this.toaster.success(data.result);
                this.cart.getCustomerRfqCount();
			}, (error) => {
				this.addingToRfq = false
			});
        }
    }

    addToWishlist(): void {
        if (!this.addingToWishlist && this.product) {
            this.addingToWishlist = true;

            this.wishlist.add(this.product).subscribe({complete: () => this.addingToWishlist = false});
        }
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            this.compare.add(this.product).subscribe({complete: () => this.addingToCompare = false});
        }
    }
}
