import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-top-companies',
  templateUrl: './block-top-companies.component.html',
  styleUrls: ['./block-top-companies.component.scss']
})
export class BlockTopCompaniesComponent implements OnInit {
  @Input() header = '';
  @Input() layout: 'classic' | 'compact' = 'classic';
  @Input() companies:any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
