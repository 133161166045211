// USA
export const locale = {
  lang: 'en',
  data: {
    HEADER: {
      LANGUAGE: "Language",
      CUSTOMER_SERVICE: "Customer Service"
    },
    LINKS: {
      HOME: "Home",
      CONTACTUS: "Contact Us",
      ABOUTUS: "About Us",
      COMPANIES: "Suplliers",
      MY_COMPANIES: "My Companies",
      BE_SUPPLIER: "Be Supplier",
      PRODUCTS: "Products",
      ALL_PRODUCTS: "All Products",
      ADDRESSES: "Addresses",
      MY_ACCOUNT: "My Account",
      NOTIFICATIONS: "Notifications",
      WISH_LIST: "Wish List",
      CART: "Cart",
      CHECKOUT: "Checkout",
      ORDER_HISTORY: "Orders",
      OLD_ORDERS: "Orders History",
      ADD_COMPANY: "Add Company",
      ADD_ADDRESS: "Add Address",
      EDIT_COMPANY: "Edit Company",
      EDIT_ADDRESS: "Edit Address",
      PASSWORD: "Password",
      LOGOUT: "Logout",
      LOGIN: "Login",
      REGISTER: "Register",
      SHOW_COMPANY: "Show Company",
      SHOW_ALL: "Show All",
      SHOW_ALL_COMPANIES: "Show All Companies",
      TERMS_AND_CONDITIONS: "Terms And Conditions",
      SHOPPING_CARTS: "Shopping Carts",
      CONTINUE: "Continue",
      BACK_TO_ORDERS_LIST: "Back to orders list",
      CREATE_AN_ACCOUNT: "Create An Account",
      FORGET_PASSWORD: "Forget Password",
      EDIT_PROFILE: "Edit Profile",
      PAGES: "Pages",
      LANGUAGE: "Language",
      ARABIC: "العربية",
      ENGLISH: "English",
      MY_RFQ: "My Requests for quotations",
      OFFERS: "Offers",
      SERVICES: "Services",
    },
    INPUTS: {
      ALL_CATEGORIES: "All Categories",
      SHOP_BY_CATEGORY: "Shop By Category",
      SEARCH_PRODUCTS: "Search Over 10000 Products",
      ADD_TO_CART: "Add To Cart",
      EMAIL: "Email Address",
      PASSWORD: "Password",
      BACK_TO_LOGIN: "Back To Login",
      BACK_TO_HOMEPAGE: "Back To Homepage",
      SUBMIT: "Submit",
      NAME: "Name",
      COMPANY_TENANT: "Company URL",
      COMPANY_NAME: "Company Name",
      PHONE_NUMBER: "Phone Number",
      UNIFIED_PHONE_NUMBER: "Unified phone number",
      COUNTRY: "Country",
      COUNTRY_CODE: "Country Code",
      CONFIRM_PASSWORD: "Confirm Password",
      ADD_COMPANY: "Add Company",
      EDIT: "Edit",
      VIEW: "View",
      CHANGE_LOGO: "Change Logo",
      ENGLISH_COMPANY_NAME: "Company English Name",
      ARABIC_COMPANY_NAME: "Company Arabic Name",
      COMPANY_EMAIL: "Company Email Address",
      COMPANY_DESCRIPTION_EN: "Company English Description",
      COMPANY_DESCRIPTION_AR: "Company Arabic Description",
      CURRENCY: "Currency",
      PAYMENT_METHODS: "Payment Methods",
      CITY: "City",
      STREET_NAME: "Street Name",
      BUILDING_NUMBER: "Building Number",
      PHONE: "Phone",
      MOBILE: "Mobile",
      TAX_FILE_NUMBER: "Tax File Number",
      MAIN_MAJOR_TYPE: "Main Major Type",
      SELECT_CATEGORIES: "Select Categories",
      ADD_COMPANY_IMAGES: "Add Company Images",
      SEARCH: "Search",
      SEARCH_CATEGORIES: "Search Categories",
      DONE: "Done",
      SELECT_YOUR_FILES: "Select Your Files",
      DRAG_AND_DROP_FILES_HERE: "Drag and drop files here",
      SAVE: "Save",
      CLOSE: "Close",
      SKU: "SKU",
      QUANTITY: "Quantity",
      CATEGORY: "Category",
      SORT_BY: "Sort By",
      DEFAULT: "Default",
      NAME_ASC: "Name (A-Z)",
      NAME_DESC: "Name (Z-A)",
      RESET_FILTERS: "Reset filters",
      SHOW_ALL_PRODUCTS: "Show Products",
      SHOW_ALL_SERVICES: "Show Services",
      PROCEED_TO_CHECKOUT: "Proceed to checkout",
      OLACE_ORDER: "Place Order",
      CANCLE: "Cancel",
      REMOVE: "Remove",
      DELETE: "Delete",
      ADD_NEW: "Add New",
      RECIPIENT_NAME: "Recipient Name",
      FIRST_NAME: "First Name",
      LAST_NAME: "Last Name",
      ADDRESS_LINE1: "Branch Address",
      ADDRESS_LINE2: "Branch Name",
      POSTAL_CODE: "Postal Code",
      SELECT_LOCATION: "Select Location",
      SEARCH_LOCATION: "Search Location",
      CURRENT_PASSWORD: "Current Password",
      NEW_PASSWORD: "New Password",
      RE_ENTER_NEW_PASSWORD: "Renter New Password",
      SHOW: "Show",
      CONTINUE: "Continue",
      CHANGE_PHOTO: "Change Photo",
      REMOVE_PHOTO: "Remove Photo",
      PRINT: "Print",
      SHELF_LIFE_TYPE: "Shelf Life Type",
      UNIT_OF_MEASURMENT: "UNIT OF MEASUREMENT",
      PACKAGING_TYPE: "Packaging Type",
      CAPACITY: "Capacity per ",
      SUBJECT: "Subject",
      MESSAGE: "Message",
      SEND_MESSAGE: "Send Message",
      MINIMUM_ORDER_QUANTITY: "Minimum order quantity",
      REQUEST_FOR_QUOTATION: "Request For Quotation",
      WRITE_NOTE_TO_SUPPLIER: "Write note to supplier...",
      SUPPLIER_NOTES: "Sipplier notes",
      YOUR_NOTES: "Your notes",
      CALCEL_RFQ: "Cancel Request",
      SEND_RFQ: "Send Request",
      CHOOSE_ESTIMATED_DELIVERY_DATE: "Choose Expected Delivery Date",
      CHOOSE_DELIVERY_DATE: "Choose Delivery Date",
      ESTIMATED_DELIVERY_DATE: "Expected Delivery Date",
      DELIVERY_DATE: "Delivery Date",
      ORDER_NUMBER: "Order Number",
      ORDER_STATUS: "Order Status",
      CUSTOMER_NAME: "Customer Name",
      CUSTOMER_EMAIL: "Customer Email",
      CUSTOMER_PHONE_NUMBER: "Customer Phone Number",
      SUB_TOTAL_PRICE: "Sub Total Price",
      TAX: "Tax",
      TOTAL_DUE_AMOUNT: "Total Due Amount",
      ITEMS_LIST: "Items List",
      ITEM_NAME: "Item Name",
      TOTAL_QUANTITIES: "Total Quantities",
      LINE_TOTAL: "Line Total",
      UNIT_PRICE_DISCOUNT: "Unit Price Discount",
      UNIT_PRICE: "Unit Price",
      UNIT_PRICE_AFTER_DISCOUNT: "Unit Price After Discount",
      LATITUDE: "Latitude",
      LONGITUDE: "Longitude",
      DELIVERED: "Delivered",
      EXPORT_PDF: "Export PDF",
      ADDRESS_LINE_1: "Address Line 1",
      ADDRESS_LINE_2: "Address Line 2",
      BUYER_COMPANY_NAME: "Buyer Company Name",
      CREATED_ON: "Created On",
      CUSTOMER_SHIPPING_ADDRESS: "Customer Shipping Address",
      BRANCH: "Branch",
      NOTES: "Notes",
      FILE_TAX_NUMBER: "File Tax Number",
      RFQ_NUMBER: "RFQ Number",
      TAX_VALUE: "Tax Value",
      VOLUME_CAPACITY: "Volume Capacity",
      FILTERS: "Filters",
      SEE_MORE: "see More",
      REORDER: "reorder",
      BACK: "Back",
    },
    TITLES: {
      TOP_PRODUCTS: "Top Products",
      TOP_COMPANIES: "Top Companies",
      SPECIAL_OFFERS: "Special Offers",
      POPULAR_CATEGORIES: "Popular Categories",
      SOCIAL: "Social",
      INFORMATION: "Information",
      LOGIN_OR: "Login Or",
      CREATE_AN_ACCOUNT: "Create An Account",
      SIGNUP: "Sign Up",
      PREVIOUS_DOCUMENTS: "Previous Selected Documents",
      PREVIOUS_IMAGES: "Previous Selected Images",
      SELECT_CATEGORIES: "Select Categories",
      CROP_IMAGE: "Crop Image",
      RELATED_PRODUCTS: "Related Products",
      RELATED_SERVICES: "Related Services",
      CATEGORIES: "Categories",
      BRANCHES: "Branches",
      CART_TOTALS: "Cart Totals",
      SHIPPING_ADDRESS: "Shipping Address",
      YOUR_ORDER: "Your Order",
      ORDER_HISTORY: "Orders History",
      ORDER_NUMBER: "Order Number", 
      NAVIGATION: "Navigation",
      DELETE_ADDRESS: "Delete Address",
      DELETE_CART: "Delete Cart",
      REMOVE_ITEM: "Remove Item",
      DELETE_ORDER: "Cancel Order",
      ADD_ADDRESS: "Add Address",
      EDIT_ADDRESS: "Edit Address",
      MAP: "Map",
      CHANGE_PASSWORD: "Change Password",
      LOGIN_TO_YOUR_ACCOUNT: "Log In to Your Account",
      MENU: "Menu",
      OUR_ADDRESSES: "Our Addresses",
      PRODUCT_DETAILS: "Product Details",
      SERVICE_DETAILS: "Service Details",
      DOCUMENTS: "Attachments",
      NOT_SENT_REQUESTS: "Not Sent Requests",
      SENT_REQUESTS: "Sent Requests For Quotations",
      RESPONSES_REQUESTS: "Responses Requests For Quotations",
      REJECTED_REQUESTS: "Rejected Requests For Quotations",
      ALL_RFQS_REQUESTS: "All Requests For Quotations",
      COMPANIES: "Companies",
    },
    HINTS: {
      FOLLOW_US_ON_SOCIAL: "Follow us on social networks",
      RIGHTS_RESERVED: "All rights reserved © Growthway 2021",
      YOU_CANT_ADD_ITEMS: "You can't add items to cart because you are not logged in, Please login to complete the proccess",
      YOU_CANT_OPEN_PAGE: "You must logged in to open page",
      FORGET_YOUR_PASSWORD: "Forget Your Password?",
      ENTER_YOUR_DETAILS_SIGNUP: "Enter your details to create your account",
      THAKS_FOR_REGISTER: "Thank you for register",
      I_AGREE_THE: "I agree the",
      TERMS_AND_CONDITIONS: "Terms and conditions",
      UPLOAD_DOCUMENTS: "Upload Documents (Commercial Register)",
      ALLOWED_SIZE_DOCUMENTS: "Allowed size for every file is 2M | Allowed formats are .doc, .docx, .pdf, .txt",
      THIS_PRICE_INCLUDES_TAX: "This price includes tax",
      SHOWING: "Showing",
      OF: "Of",
      RESULTS: "Results",
      NO_MATCHING_ITEMS: "No matching items",
      NO_MATCHING_SERVICES: "No matching services",
      NO_MATCHING_COMPANIES: "No matching companies",
      TRY_RESETTING_FILTERS: "Try resetting the filters",
      YOUR_CARTS_EMPTY: "Your shopping cart is empty!",
      SELECT_SHIPPING_ADDRESS: "Select Your shipping address or",
      ADD_NEW_SHIPPING_ADDRESS: "Add New Shipping Address",
      SELECT_PAYMENT_METHOD: "Select Payment Method",
      WRITE_NOTE_TO_SELLER: "Write Note To Seller",
      YOU_CANT_CHECKPUT_WITHOUT_ADDRESS: "You can't checkout if you don't have any shipping address.",
      NOTE_TO_BUYER: "Note To Buyer",
      ORDER_WAS_PLACED_ON: "Order was placed on",
      FROM: "From",
      AND_IS_CURRENTLY: "and is currently",
      NOTES: "Notes",
      DEFAULT: "Default",
      ARE_YOU_SURE_DELETE_ADDRESS: "Are you sure want to delete this address?",
      ARE_YOU_SURE_DELETE_ORDER: "Are you sure want to cancel this order?",
      ARE_YOU_SURE_DELETE_CART: "Are you sure want to cancel this cart?",
      MAKE_AS_DEFAULT_ADDRESS: "Make as default address",
      LATITUDE: "Latitude",
      LONGITUDE: "Longitude",
      ENTER_EMAIL_TO_RESET_PASSWORD: "Enter your email to reset your password",
      PASSWORD_LINK_SENT_TO_EMAIL: "Password reset link was sent. Please check your email.",
      SALE: "Sale",
      YOUR_WISH_LIST_IS_EMPTY: "Your wish list is empty!",
      TO_VERIFY_YOUR_PROFILE: 'Please upload your company papers and documents such as commercial register to review these documents from our admins to make your account verified.',
      WAIT_TO_VERIFY_YOUR_PROFILE: 'Our admins gonna check your documents as soon as possible.',
      PRESS_HERE_TO_BE_VERIFIED: "Press here to be verified",
      THANK_YOU: "Thank You",
      YOUR_ORDER_PLACED: "Your order has been received",
      GO_TO_HOMEPAGE: "Go to home page",
      EXPECTED_DELIVERY_DATE: "Expected Delivery Date",
      DELIVERY_DATE: "Delivery Date",
      LEAVE_US_MESSAGE: "Leave us a Message",
      COMMING_SOON: "Comming Soon",
      NO_REQUESTS_FOR_QUOTATIONS: "No requests for quotations",
      ARE_YOU_SURE_DELETE_RFQ: "Are you sure want to cancle this request for quotation?",
      REMAINING: "This RFQ will be expired after: ",
      SEEN: 'Seen',
      YOUR_NOTIFICATIONS_LIST_IS_EMPTY: "Your notifications list is empty",
      THIS_PRICE_CHANGED: "This price has been changed from",
      TO: "to",
    },
    VALIDATIONS: {
      email_required: 'Email is required',
      email_email: ' Email is invalid',
      email_minlength: 'Email should have at least 6 symbols',
      name_required: 'Name is required',
      countryId_required: 'Country is required',
      phoneNumber_required: 'Phone is required',
      phoneNumber_pattern: 'Phone is invalid and should not has 0 in first position and should not has special characters',
      phoneNumber_maxlength: 'Maximum length is 9',
      phoneNumber_minlength: 'Minimum length is 9',
      password_required: 'Password is required',
      password_minlength: 'Password should have at least 6 symbols',
      cPassword_required: 'Confirm Password is required',
      cPassword_minlength: 'Confirm Password should have at least 6 symbols',
      cPassword_mustMatch: 'Passsword and Confirm Password didn\'t match.',
      PHOTO_SIZE: "Please select photo whith size less than 2M",
      REQUIRED_FIELD: "This Field Is Required",
      COMPANY_NAME: "Company name is invalid",
      COMPANY_TENANT_NAME: "Company tenant name is invalid",
      cityId_required: 'City is required',
      oldPassword_required: 'Current Password is required',
      newPassword_required: 'Password is required',
      newPassword_minlength: 'Password should have at least 6 symbols',
      confirmPassword_required: 'Reenter New Password is required',
      confirmPassword_minlength: 'Reenter New Password should have at least 6 symbols',
      confirmPassword_mustMatch: 'Passsword and Reenter New Password didn\'t match.',
      THIS_TENANT_IS_ALREADY_EXISTS: "This tenant is already exists",
      UNIFIED_PHONE_NUMBER_INVALID: "Unified Phone Number is invalid",
      PHONE_NUMBER_INVALID: "Phone Number is invalid",
      MOBILE_NUMBER_INVALID: "Mobile Number is invalid",
    },
    TABLE: {
      IMAGE: "Image",
      PRODUCT: "Product",
      PRICE: "Price",
      QUANTITY: "Quantity",
      TOTAL: "Total",
      SUBTOTAL: "Subtotal",
      DISCOUNT: "Discount",
      TAX: "Value-Added Tax",
      ORDER: "Order",
      DATE: "Date",
      COMPANY: "Company",
      STATUS: "Status",
      ITEM: "Item(s)",
      FOR: "For",
      TOTAL_DUE_AMOUNT: 'Total Due Amount',
      PRICE_AFTER_DISCOUNT: "Unit Price After Discount",
    },
    STATIC: {
      KSA: "KSA",
      KSA_DETAILS1: "Riyadh - Alwaha - Uthman Ibn Affan Rd",
      KSA_DETAILS2: "Building No.8736 - Floor3 - OfficeNo.18",
      UAE: "UAE",
      UAE_DETAILS1: "Dubai - JLT - JBC2 - Office No.1509",
      JORDAN: "JORDAN",
      JORDAN_DETAILS1: "Amman - Jubaiha - AlManhal Center",
      ABOUT_US_MAIN: "GrowthWay is the first smart business platform that connects suppliers with food service providers, and those who work on the same field around the world. We provide each supplier with his own page on the app as he can add an unlimited number of products with their pictures and description. He is also allowed to manage many operations through the app as purchase orders, delivery, tracking of delivery, sending targeted advertisements, and analyzing information through professional and special reports.",
      ABOUT_US_OUR_VISION: "Our Vision",
      ABOUT_US_OUR_VISION_1: "We seek to be the best digital platform that connects suppliers of e-commerce with the business sector easily.",
      ABOUT_US_OUR_VISION_2: "We seek to change the shape of food industry and trade in order to transfer it electronically.",
      ABOUT_US_FLEXIBILITY: "Flexibility",
      ABOUT_US_EFFICIENCY: "Efficiency",
      ABOUT_US_SPEED: "Speed Of Achievement",
      ABOUT_US_SUSTAINABLE: "Sustainable Growth",
      ABOUT_US_TRANSPARENCY: "Transparency",
      ABOUT_US_COMMUNICATION: "Communication",
      ABOUT_US_OUR_MISSION: "Our Mission",
      ABOUT_US_OUR_MISSION_1: "Creating an advanced platform that brings together all the companies specialized in the food industry to facilitate business communication, to accelerate business and to reduce costs in order to achieve sustainable growth.",
      ABOUT_US_OUR_MISSION_2: "Providing a website for each company to manage its marketing and operating processes through it.",
      ABOUT_US_OUR_MISSION_3: "Simplifying all logistics services and giving you the right to track all your operations until they reach the customer.",
      ABOUT_US_OUR_MISSION_4: "Designing automated business feasibility modules to help investors evaluate opportunities effectively and to secure higher return on investment rates.",
      ABOUT_US_OUR_MISSION_5: "Vertical integration of B2B to enhance efficiency, to maximize employee utilization, and to control operating costs.",
      ABOUT_US_OUR_MISSION_6: "Using data and reporting techniques to generate critical business insights, to assess performance, to predict customers’ needs in addition to the market trends and to ensure the best investment opportunities.",
      ABOUT_US_OUR_SERVICES: "Our Services",
      ABOUT_US_OUR_SERVICES_ACCESS: "360˚ Access",
      ABOUT_US_OUR_SERVICES_ACCESS_1: "All suppliers at your fingertip",
      ABOUT_US_OUR_SERVICES_ACCESS_2: "Best price",
      ABOUT_US_OUR_SERVICES_ACCESS_3: "Best location",
      ABOUT_US_OUR_SERVICES_ACCESS_4: "Best service",
      ABOUT_US_OUR_SERVICES_ACCESS_5: "Reviews",
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT: "Order Management",
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT_1: "Place your order",
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT_2: "Track your order",
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT_3: "In full & In Time",
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT_4: "Customizable Dashboards",
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS: "IT Solutions",
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS_1: "Web based Tailored POS",
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS_2: "Comprehensive Accounting Systems",
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS_3: "Advanced Filtering Tools",
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS_4: "Real time analytics reports",
      ABOUT_US_OUR_SERVICES_MARKET_INSIGHTS: "Market Insights",
      ABOUT_US_OUR_SERVICES_MARKET_INSIGHTS_1: "Potential Leads",
      ABOUT_US_OUR_SERVICES_MARKET_INSIGHTS_2: "Market trends by segment",
      ABOUT_US_OUR_SERVICES_MARKET_INSIGHTS_3: "Market Share Analysis",
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION: "Business Optimization",
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_1: "Operations Standardization",
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_2: "Cost Control",
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_3: "Training & Development",
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_4: "Process Mapping",
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_5: "Quality Control",
      ABOUT_US_OUR_SERVICES_BUSINESS_PLANNING: "Business Planning",
      ABOUT_US_OUR_SERVICES_BUSINESS_PLANNING_1: "Full fledge feasibility studies",
      ABOUT_US_OUR_SERVICES_BUSINESS_PLANNING_2: "Advisory Services based on scientific surveys",
      ABOUT_US_DOCUMENTATION: "Documentation",
      ABOUT_US_DOCUMENTATION_1: "We in Growth Way guarantee that all suppliers and service providers on Growth way’s platform are trusted and certified by Commercial licenses and carefully selected by our specialized Marketing team.",
    },
    LOOKUPS: {
      MINUTES: 'Minutes',
      HOUR: 'Hour',
      DAY: 'Day',
      MONTH: 'Month',
      YEAR: 'Year',
      JANUARY: 'January',
      FEBRUARY: 'February',
      MARCH: 'March',
      APRIL: 'April',
      MAY: 'May',
      JUNE: 'June',
      JULY: 'July',
      AUGUST: 'August',
      SEPTEMBER: 'September',
      OCTOBER: 'October',
      NOVEMBER: 'November',
      DECEMBER: 'December',
      Supplier: "Supplier",
      ServiceProvider: "Service Provider",
      SupplierAndServiceProvider: "Supplier And Service Provider",
      AND: "and",
    }
  }
};
