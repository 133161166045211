<div class="site-header">
    <!-- <app-header-topbar></app-header-topbar> -->

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo">
            <a routerLink="/">
                <img src="./../../../assets/images/logos/logo.svg" alt="">
            </a>
        </div>
        <div class="site-header__search">
            <app-search location="header"></app-search>
        </div>
        <div class="site-header__phone">
            <!-- <div class="site-header__phone-title">{{'HEADER.CUSTOMER_SERVICE' | translate}}</div>
            <div class="site-header__phone-number phone-number">{{ store.primaryPhone }}</div> -->
            <app-header-topbar></app-header-topbar>
        </div>
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav [departments]="layout === 'classic'" [logo]="layout === 'compact'" [search]="layout === 'compact'" stickyMode="pullToShow"></app-header-nav>
    </div>
</div>
