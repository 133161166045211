<div class="container">
    <div class="mb-3 product product--layout--{{ layout }}" *ngIf="product">
        <div class="product__content">
            <!-- .product__gallery -->
            <div class="product__gallery position-relative">
                <app-product-gallery [productLayout]="layout" [images]="product?.images"></app-product-gallery>
                <div *ngIf="product?.compareAtPrice && !product?.hiddenPrice" class="product-card__badges-list">
                    <div class="product-card__badge product-card__badge--sale">{{'HINTS.SALE' | translate}}</div>
                </div>
            </div>
            <!-- .product__gallery / end -->
            <!-- .product__info -->
            <div class="product__info">
                <div class="product__wishlist-compare">
                    <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Wishlist"
                            appClick (click)="addToWishlist()"
                            [ngClass]="{'btn-loading': addingToWishlist}">
                        <app-icon name="wishlist-16" size="16"></app-icon>
                    </button>
                    <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Compare"
                            appClick (click)="addToCompare()"
                            [ngClass]="{'btn-loading': addingToCompare}">
                        <app-icon name="compare-16" size="16"></app-icon>
                    </button>
                </div>
                <h1 class="product__name">{{ product?.name }}</h1>
                <div class="mb-2 product__description">
                    {{ product?.description }}
                </div>
                <div class="mb-3 d-flex align-items-center">
                    <span class="w-25px">
                        <img class="w-100" [src]="product.countryFlag" alt="{{ product.originCountry }}">
                    </span>
                    <p class="mb-0 mx-2 small-text product-card-company-name">{{ product.originCountry }}</p>
                </div>
                <div class="mb-0 product__prices">
                    <ng-container *ngIf="product?.compareAtPrice && !product.hiddenPrice">
                        <span class="product__new-price">{{ product?.price?.toFixed(2) }} <span class="product-currency">{{product?.currency}}</span></span>{{ ' ' }}
                        <span class="product__old-price">{{ product?.compareAtPrice?.toFixed(2) }} <span class="product-currency">{{product?.currency}}</span></span>
                    </ng-container>
                    <ng-container *ngIf="!product?.compareAtPrice && !product.hiddenPrice">
                        {{ product?.price?.toFixed(2) }} <span class="product-currency">{{product?.currency}}</span>
                    </ng-container>
                    <ng-container *ngIf="product?.unitOfMeasurement && !product.hiddenPrice">
                        <span class="h6"> / {{ product?.unitOfMeasurement }}</span>
                    </ng-container>
                </div>
                <p *ngIf="!product.hiddenPrice" class="small-text">{{'HINTS.THIS_PRICE_INCLUDES_TAX' | translate}}</p>
                <div class="mb-3 d-flex align-items-center">
                    <span class="cyrcle h-50px w-50px overflow-hidden border d-flex align-items-center">
                        <img class="w-100" [src]="product?.companyLogo" alt="{{ product?.companyName }}">
                    </span>
                    <a class="mx-2 h6" appClick routerLink="/companies/{{ product?.companySlug }}">{{ product?.companyName }}</a>
                </div>
                <!-- <div class="product__rating">
                    <div class="product__rating-stars">
                        <app-rating [value]="product?.rating"></app-rating>
                    </div>
                    <div class="product__rating-legend">
                        <a href="" appClick>{{ product?.reviews }} Reviews</a><span>/</span><a href="" appClick>Write A Review</a>
                    </div>
                </div> -->
                <!-- <ul class="product__features">
                    <li>Speed: 750 RPM</li>
                    <li>Power Source: Cordless-Electric</li>
                    <li>Battery Cell Type: Lithium</li>
                    <li>Voltage: 20 Volts</li>
                    <li>Battery Capacity: 2 Ah</li>
                </ul> -->
                <!-- <ul class="product__meta">
                    <li class="product__meta-availability">Availability: <span class="text-success">In Stock</span></li>
                    <li>Brand: <a href="" appClick>Wakita</a></li>
                    <li *ngIf="product?.sku">{{'INPUTS.SKU' | translate}}: {{ product?.sku }}</li>
                </ul> -->
            </div>
            <!-- .product__info / end -->
            <!-- .product__sidebar -->
            <div class="product__sidebar">
                <!-- <div class="product__availability">
                    Availability: <span class="text-success">In Stock</span>
                </div> -->
                <!-- .product__options -->
                <form class="product__options">
                    <!-- <div class="form-group product__option">
                        <label class="product__option-label">Color</label>
                        <div class="input-radio-color">
                            <div class="input-radio-color__list">
                                <label class="input-radio-color__item input-radio-color__item--white" style="color: #fff;" data-toggle="tooltip" title="White">
                                    <input type="radio" name="color">
                                    <span></span>
                                </label>
                                <label class="input-radio-color__item" style="color: #ffd333;" data-toggle="tooltip" title="Yellow">
                                    <input type="radio" name="color">
                                    <span></span>
                                </label>
                                <label class="input-radio-color__item" style="color: #ff4040;" data-toggle="tooltip" title="Red">
                                    <input type="radio" name="color">
                                    <span></span>
                                </label>
                                <label class="input-radio-color__item input-radio-color__item--disabled" style="color: #4080ff;" data-toggle="tooltip" title="Blue">
                                    <input type="radio" name="color" disabled>
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div> -->
                    <mat-expansion-panel class="mb-3" [expanded]="'true'">
                        <mat-expansion-panel-header class="border-bottom">
                            <mat-panel-title *ngIf="product?.type === 1" class="font-bold">
                                {{'TITLES.PRODUCT_DETAILS' | translate}}
                            </mat-panel-title>
                            <mat-panel-title *ngIf="product?.type === 2" class="font-bold">
                                {{'TITLES.SERVICE_DETAILS' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="pt-3">
                            <div class="form-group product__option d-flex align-items-center mb-1" *ngIf="product?.categories?.id">
                                <label class="product__option-label small-text mb-0">{{'INPUTS.CATEGORY' | translate}}:</label>
                                <a *ngIf="product?.type === 1" class="mx-2 font-bold" [routerLink]="['/products']" [queryParams]="{ categorySlug: product?.categories?.slug, categoryName: product?.categories?.name}">{{product?.categories?.name}}</a>
                                <a *ngIf="product?.type === 2" class="mx-2 font-bold" [routerLink]="['/services']" [queryParams]="{ categorySlug: product?.categories?.slug, categoryName: product?.categories?.name}">{{product?.categories?.name}}</a>
                            </div>
                            <div class="form-group product__option d-flex align-items-center mb-1" *ngIf="product?.volumeCapacity">
                                <label class="product__option-label small-text mb-2">{{'INPUTS.VOLUME_CAPACITY' | translate}}:</label>
                                <label class="product__option-label font-bold h6 px-2"> {{ product?.volumeCapacity }} {{product?.volumeMeasurement}}</label>
                            </div>
                            <div class="form-group product__option d-flex align-items-center mb-1" *ngIf="product?.capacity">
                                <label class="product__option-label small-text mb-2">{{'INPUTS.CAPACITY' | translate}} {{ product?.unitOfMeasurement }}:</label>
                                <label class="product__option-label font-bold h6 px-2">{{ product?.capacity }}</label>
                            </div>
                            <div class="form-group product__option d-flex align-items-center mb-1" *ngIf="product?.sku">
                                <label class="product__option-label small-text mb-2">{{'INPUTS.SKU' | translate}}:</label>
                                <label class="product__option-label font-bold h6 px-2">{{ product?.sku }}</label>
                            </div>
                            <div class="form-group product__option d-flex align-items-center mb-1" *ngIf="product?.shelfLifeType && product?.shelfLifeDuration">
                                <label class="product__option-label small-text mb-2">{{'INPUTS.SHELF_LIFE_TYPE' | translate}}:</label>
                                <label class="product__option-label font-bold h6 px-2">{{ product?.shelfLifeDuration }} ({{'LOOKUPS.'+(product?.shelfLifeType | uppercase) | translate}})</label>
                            </div>
                            <div class="form-group product__option d-flex align-items-center mb-1" *ngIf="product?.minimumOrderQuantity">
                                <label class="product__option-label small-text mb-2">{{'INPUTS.MINIMUM_ORDER_QUANTITY' | translate}}:</label>
                                <label class="product__option-label font-bold h6 px-2">{{ product?.minimumOrderQuantity }} {{ product?.unitOfMeasurement }}</label>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <div class="form-group product__option">
                        <label class="product__option-label">{{'INPUTS.QUANTITY' | translate}}</label>
                        <div class="product__actions">
                            <div class="product__actions-item">
                                <app-input-number aria-label="Quantity" class="product__quantity" size="md" [min]="1" [formControl]="quantity"></app-input-number>
                            </div>
                            <div *ngIf="!product.hiddenPrice" class="product__actions-item product__actions-item--addtocart">
                                <button type="button" class="btn btn-primary"
                                        [ngClass]="{'btn-loading': addingToCart}"
                                        appClick (click)="addToCart()">{{'INPUTS.ADD_TO_CART' | translate}}</button>
                            </div>
                            <div *ngIf="product.hiddenPrice" class="product__actions-item product__actions-item--addtocart">
                                <button class="btn btn-warning" type="button" appClick (click)="addRfq()" [ngClass]="{'btn-loading': addingToRfq}">{{'INPUTS.REQUEST_FOR_QUOTATION' | translate}}</button>
                            </div>
                            <div class="product__actions-item product__actions-item--wishlist">
                                <button type="button" class="btn btn-secondary btn-svg-icon" data-toggle="tooltip" title="Wishlist"
                                        [ngClass]="{'btn-loading': addingToWishlist}"
                                        appClick (click)="addToWishlist()">
                                    <app-icon name="wishlist-16" size="16"></app-icon>
                                </button>
                            </div>
                            <!-- <div class="product__actions-item product__actions-item--compare">
                                <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Compare"
                                        [ngClass]="{'btn-loading': addingToCompare}"
                                        appClick (click)="addToCompare()">
                                    <app-icon name="compare-16" size="16"></app-icon>
                                </button>
                            </div> -->
                        </div>
                    </div>
                </form>
                <!-- .product__options / end -->
            </div>
            <!-- .product__end -->
            <div class="product__footer">
                <div class="product__tags tags">
                    <div class="tags__list">
                        <ng-container *ngFor="let badge of product?.badges">
                            <a [routerLink]="['/products']" [queryParams]="{ searchText: badge }" appClick>{{badge}}</a>
                        </ng-container>
                    </div>
                </div>
    
                <!-- <app-share-buttons
                    class="product__share-buttons"
                    [buttons]="['facebook', {type: 'twitter', label: 'Tweet'}, 'pinterest']"
                    [pageUrl]="root.product(product)|absoluteUrl:'angular'"
                    [pageTitle]="product?.name"
                    [pageImage]="product?.images[0]|absoluteUrl:'html'"
                ></app-share-buttons> -->
            </div>
        </div>
    </div>
</div>
