<!-- .block-categories -->
<div class="block block--highlighted block-categories block-categories--layout--{{ layout }}">
    <div class="container">
        <app-block-header [header]="header"></app-block-header>

        <div class="block-categories__list top-categories-home">
            <div *ngFor="let category of categories" class="block-categories__item category-card category-card--layout--{{ layout }}">
                <div class="category-card__body">
                    <a [routerLink]="['/products']" [queryParams]="{ categorySlug: category.slug, categoryName: category.name}">
                        <img [src]="category.iconPath" alt="{{ category.name }}">
                        <div class="category-card__name">
                            {{ category.name }}
                        </div>
                    </a>
                    <!-- <div class="category-card__image home">
                        <a ><img [src]="category.iconPath" alt="{{ category.name }}"></a>
                    </div>
                    <div class="category-card__content">
                        <div class="category-card__name">
                            <a [routerLink]="['/products']" [queryParams]="{ categorySlug: category.slug, categoryName: category.name}">{{ category.name }}</a>
                        </div>
                        <div class="category-card__all">cccc
                            <a [routerLink]="['/products']" [queryParams]="{ categorySlug: category.slug, categoryName: category.name}">{{'LINKS.SHOW_ALL' | translate}}</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-categories / end -->
