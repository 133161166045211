import { Component, Input, OnInit } from '@angular/core';
import { Category } from '../../../shared/interfaces/category';
import { RootService } from '../../../shared/services/root.service';
import { HomeService } from '../../home/service/home.service';

@Component({
    selector: 'app-block-categories',
    templateUrl: './block-categories.component.html',
    styleUrls: ['./block-categories.component.scss']
})
export class BlockCategoriesComponent implements OnInit {
    @Input() header = '';
    @Input() layout: 'classic' | 'compact' = 'classic';
    @Input() categories:any = [];

    constructor(
        public root: RootService,
        private homeService: HomeService
    ) { }

    ngOnInit(): void { }
}
