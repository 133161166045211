import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialLinksItem } from './components/contacts/contacts.component';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  items: SocialLinksItem[];
  constructor(private http:HttpClient) { }

  footerContent(): Observable<any> {
      return this.http.get<any>('FooterContent/GetFooterContent');
  }
}
