import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HasCompanyService {
  constructor() {}
  hasComp: Subject<boolean> = new Subject();

  private isHasCompany: boolean = false;

  set setIsHasCompany(index: boolean) {
    this.isHasCompany = index;
    this.hasComp.next(index);
  }

  get setIsHasCompany() {
    return this.isHasCompany;
  }
}