import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { posts } from 'src/data/blog-posts';
import { Brand } from 'src/app/shared/interfaces/brand';
import { ShopService } from 'src/app/shared/api/shop.service';
import { Product } from 'src/app/shared/interfaces/product';
import { Category } from 'src/app/shared/interfaces/category';
import { BlockHeaderGroup } from 'src/app/shared/interfaces/block-header-group';
import { HomeService } from '../../service/home.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

interface ProductsCarouselGroup extends BlockHeaderGroup {
    products$: Observable<Product[]>;
}

interface ProductsCarouselData {
    abort$: Subject<void>;
    loading: boolean;
    products: Product[];
    groups: ProductsCarouselGroup[];
}


@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})


export class HomePageComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    bestsellers$: Observable<Product[]>;
    brands$: Observable<Brand[]>;
    popularCategories$: Observable<Category[]>;
    columnTopRated$: Observable<Product[]>;
    columnSpecialOffers$: Observable<Product[]>;
    columnBestsellers$: Observable<Product[]>;
    posts = posts;
    featuredProducts: ProductsCarouselData;
    latestProducts: ProductsCarouselData;
    homeData: any;
    header: string = 'Top Companies';
    constructor(
        private homeService: HomeService,
        private shop: ShopService,
        private loderService: LoaderService,
    ) { }

    ngOnInit(): void {
        this.getHome();
        this.bestsellers$ = this.shop.getBestsellers(7);
        this.brands$ = this.shop.getPopularBrands();
        // this.popularCategories$ = this.shop.getCategoriesBySlug([
        //     'power-tools',
        //     'hand-tools',
        //     'machine-tools',
        //     'power-machinery',
        //     'measurement',
        //     'clothes-and-ppe',
        // ], 1);
        this.columnTopRated$ = this.shop.getTopRated(3);
        this.columnSpecialOffers$ = this.shop.getSpecialOffers(3);
        this.columnBestsellers$ = this.shop.getBestsellers(3);

        this.featuredProducts = {
            abort$: new Subject<void>(),
            loading: false,
            products: [],
            groups: [
                {
                    name: 'All',
                    current: true,
                    products$: this.shop.getFeaturedProducts(null, 8),
                },
                {
                    name: 'Power Tools',
                    current: false,
                    products$: this.shop.getFeaturedProducts('power-tools', 8),
                },
                {
                    name: 'Hand Tools',
                    current: false,
                    products$: this.shop.getFeaturedProducts('hand-tools', 8),
                },
                {
                    name: 'Plumbing',
                    current: false,
                    products$: this.shop.getFeaturedProducts('plumbing', 8),
                },
            ],
        };
        this.groupChange(this.featuredProducts, this.featuredProducts.groups[0]);

        this.latestProducts = {
            abort$: new Subject<void>(),
            loading: false,
            products: [],
            groups: [
                {
                    name: 'All',
                    current: true,
                    products$: this.shop.getLatestProducts(null, 8),
                },
                {
                    name: 'Power Tools',
                    current: false,
                    products$: this.shop.getLatestProducts('power-tools', 8),
                },
                {
                    name: 'Hand Tools',
                    current: false,
                    products$: this.shop.getLatestProducts('hand-tools', 8),
                },
                {
                    name: 'Plumbing',
                    current: false,
                    products$: this.shop.getLatestProducts('plumbing', 8),
                },
            ],
        };
        this.groupChange(this.latestProducts, this.latestProducts.groups[0]);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    groupChange(carousel: ProductsCarouselData, group: BlockHeaderGroup): void {
        carousel.loading = true;
        carousel.abort$.next();

        (group as ProductsCarouselGroup).products$.pipe(
            tap(() => carousel.loading = false),
            takeUntil(merge(this.destroy$, carousel.abort$)),
        ).subscribe(x => carousel.products = x);
    }

    getHome() {
        this.loderService.setIsLoading = true;
        this.homeService.getHome().subscribe((data) => {
            this.homeData = data.result;
            this.loderService.setIsLoading = false;
        }, (error) => {
            this.loderService.setIsLoading = false;
        });
    }

}
