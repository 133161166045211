import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    constructor(private http: HttpClient) { }

    getUser(): Observable<any> {
        return this.http.get<any>('Manage/GetUser');
    }

    getUserNotifications(dataFilter): Observable<any> {
        return this.http.post<any>('Notifications/GetUserNotifications', dataFilter);
    }

    readNotification(data): Observable<any> {
        return this.http.post<any>('Notifications/ReadNotification', data);
    }

    deleteNotification(data): Observable<any> {
        return this.http.post<any>('Notifications/DeleteNotification', data);
    }

}