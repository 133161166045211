import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/modules/account/service/account.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { HeaderService } from '../../header.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { HasCompanyService } from 'src/app/shared/services/has-company.service';
import { CompaniesService } from 'src/app/modules/companies/companies.service';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    loginForm: FormGroup;
    returnUrl: string;
    user:any;
    private unsubscribe: Subscription[] = [];
    constructor(private fb: FormBuilder, private authService: AccountService, private route: ActivatedRoute, public hasCompanyService:HasCompanyService,
        private router: Router, private loderService: LoaderService, private headerService:HeaderService,public cart: CartService,private companiesService:CompaniesService,) { }

    ngOnInit(): void {
        this.initForm();
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'.toString()] || '/';

        if (localStorage.getItem('token')) {
            this.getUser();
        }
    }
    
    initForm() {
        this.loginForm = this.fb.group({
            email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.email,
                ]),
            ],
            password: [
                '',
                Validators.compose([
                    Validators.required,
                ]),
            ]
        })
    }

    submit() {
        this.loderService.setIsLoading = true;
        this.authService.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value)
        .pipe(first())
        .subscribe((data: any) => {
            localStorage.setItem("token", data.result.authResponse.accessToken);
            this.companiesService.registeredCompanies().subscribe((data) => {
                this.hasCompanyService.setIsHasCompany = data.result.registered;
            })
            this.router.navigate(["/"]);
            this.getUser();
            this.closeMenu.emit();
            this.loderService.setIsLoading = false;
            this.cart.getCustomerCartCount();
            this.cart.getCustomerRfqCount();
            this.cart.getUserNotificationsCount();
            if(localStorage.getItem('token')) {
                this.companiesService.registeredCompanies().subscribe((data) => {
                    this.hasCompanyService.setIsHasCompany = data.result.registered;
                })
            }
        },(error) => {
            this.loderService.setIsLoading = false;
        });
    }

    checkIfLogin() {
        if (localStorage.getItem('token')) {
            return true;
        }
        else {
            return false;
        }
    }

    getUser() {
        this.headerService.getUser().subscribe((data) => {
            this.user = data.result;
        });
    }

    logout() {
        localStorage.removeItem('token');
        this.router.navigate(['/auth/login'], {
            queryParams: {},
        });
        this.cart.getCustomerCartCount();
        this.cart.getCustomerRfqCount();
    }
}
