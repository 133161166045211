<app-page-header [header]="'Offcanvas Cart'" [breadcrumbs]="[
    {label: 'Home',           url: root.home()},
    {label: 'Offcanvas Cart', url: ''}
]"></app-page-header>

<div class="block-empty__body">
    <div class="block-empty__message">Click on the button to open the offcanvas cart!</div>
    <div class="block-empty__actions">
        <button type="button" class="btn btn-primary btn-sm" (click)="offcanvasCart.open()">Open Cart</button>
    </div>
</div>
