import { MobileMenuItem } from '../app/shared/interfaces/mobile-menu-item';

export const mobileMenu: MobileMenuItem[] = [
    {type: 'link', label: 'HOME', url: '/',},
    {type: 'link', label: 'PRODUCTS', url: '/products'},
    {type: 'link', label: 'OFFERS', url: '/products/offers/list'},
    {type: 'link', label: 'COMPANIES', url: '/companies/companies-page'},

    {type: 'link', label: 'MY_ACCOUNT', url: '/auth/profile', children: [
        {type: 'link', label: 'MY_COMPANIES', url: '/companies',},
        {type: 'link', label: 'EDIT_PROFILE',    url: '/auth/profile'},
        {type: 'link', label: 'PASSWORD', url: '/auth/password'},
        {type: 'link', label: 'ADDRESSES',    url: '/auth/addresses'},
        {type: 'link', label: 'ORDER_HISTORY',   url: '/auth/orders'},
        {type: 'link', label: 'MY_RFQ',   url: '/rfq'},
        {type: 'link', label: 'OLD_ORDERS',   url: '/auth/orders/old'},
        {type: 'link', label: 'NOTIFICATIONS',   url: '/auth/notifications'},
    ]},

    {type: 'button', label: 'PAGES', children: [
        {type: 'link', label: 'ABOUTUS',             url: '/site/about-us'},
        {type: 'link', label: 'CONTACTUS',           url: '/site/contact-us'},
    ]},

    {type: 'button', label: 'LANGUAGE', children: [
        {type: 'button', label: 'ARABIC', data: {language: 'ar', flag: 'assets/images/flags/008-saudi-arabia.svg',}},
        {type: 'button', label: 'ENGLISH',  data: {language: 'en', flag: 'assets/images/flags/226-united-states.svg',}},
    ]}
];
