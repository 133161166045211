import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { mobileMenu } from '../../../../../data/mobile-menu';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
import { Router } from '@angular/router';
import { CartService } from 'src/app/shared/services/cart.service';
import { TranslationService } from 'src/app/shared/i18n/translation.service';

interface LanguageFlag {
    lang: string;
    name: string;
    flag: string;
    active?: boolean;
}

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})

export class MobileMenuComponent implements OnDestroy, OnInit {
    private destroy$: Subject<any> = new Subject();
    language: LanguageFlag;
    languages: LanguageFlag[] = [
        {
            lang: 'en',
            name: 'English',
            flag: 'assets/images/flags/226-united-states.svg',
        },
        {
            lang: 'ar',
            name: 'العربية',
            flag: 'assets/images/flags/008-saudi-arabia.svg',
        },
    ];
    isOpen = false;
    links: MobileMenuItem[] = mobileMenu;

    constructor(public mobilemenu: MobileMenuService, private router: Router, private cart: CartService, public translationService: TranslationService,) { }

    ngOnInit(): void {
        this.mobilemenu.isOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => this.isOpen = isOpen);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onItemClick(event: MobileMenuItem): void {
        if (event.type === 'link') {
            this.mobilemenu.close();
        }

        if (event.data && event.data.language) {
            console.log(event.data.language); // change language
            this.setLanguageWithRefresh(event.data.language);
        }
    }

    setLanguageWithRefresh(lang) {
        this.setLanguage(lang);
        window.location.reload();
    }

    setLanguage(lang) {
        var file = document.getElementById('rtl-file');
        if(lang === 'ar') {
          if(!file){
            var head  = document.getElementsByTagName('head')[0];
            var link  = document.createElement('link');
            link.rel  = 'stylesheet';
            link.type = 'text/css';
            link.href = './assets/css/bootstrap-rtl.min.css';
            link.media = 'all';
            var link2  = document.createElement('link');
            link2.rel  = 'stylesheet';
            link2.type = 'text/css';
            link2.href = './assets/css/style-rtl.css';
            link2.media = 'all';
            var html  = document.getElementsByTagName('html')[0];
            head.appendChild(link);
            head.appendChild(link2);
            setTimeout(() => {
              html.id="rtl-file";
              html.dir="rtl";
              html.style.direction = "rtl";
            },200);
          }
        }
        this.languages.forEach((language: LanguageFlag) => {
            if (language.lang === lang) {
                language.active = true;
                this.language = language;
            } else {
                language.active = false;
            }
        });
        this.translationService.setLanguage(lang);
    }

    checkIfLogin() {
        if (localStorage.getItem('token')) {
            return true;
        }
        else {
            return false;
        }
    }

    logout() {
        localStorage.removeItem('token');
        this.router.navigate(['/auth/login'], {
            queryParams: {},
        });
        this.cart.getCustomerCartCount();
        this.cart.getCustomerRfqCount();
    }

}
