
<div id="contentToConvert">
    <div [ngTemplateOutlet]="orderDetailsView"></div>
</div>
<ng-template #orderDetailsView>
    <div class="logo-container">        
        <div id="company_logo" class="d-flex align-items-center mx-3">
            <img alt="Logo" class="company-logo h-auto w-50px mx-3" src="{{orderDetails?.logo}}">
        </div>
        <h6 class="text-center mb-0">{{orderDetails?.companyName}}</h6>
        <p class="text-right">{{'INPUTS.FILE_TAX_NUMBER' | translate}}: {{orderDetails?.taxFileNumber || '-----'}}</p>
    </div>
    <div class="row">
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.orderNumber">
            <p *ngIf="type === 'RFQ'" class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.RFQ_NUMBER' | translate}}</span>: {{orderDetails?.orderNumber || '----'}}</p>
            <p *ngIf="type === 'ORDER'" class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.ORDER_NUMBER' | translate}}</span>: {{orderDetails?.orderNumber || '----'}}</p>
        </div>
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.buyerCompanyName">
            <p class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.BUYER_COMPANY_NAME' | translate}}:</span><img *ngIf="orderDetails?.buyerCompanyLogo" src="{{orderDetails?.buyerCompanyLogo}}" class="buyer-logo" alt="">{{orderDetails?.buyerCompanyName || '----'}}</p>
        </div>
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.name">
            <p class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.CUSTOMER_NAME' | translate}}:</span> {{orderDetails?.name || '----'}}</p>
        </div>
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.email">
            <p class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.CUSTOMER_EMAIL' | translate}}:</span> {{orderDetails?.email || '----'}}</p>
        </div>
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.phone">
            <p class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.CUSTOMER_PHONE_NUMBER' | translate}}:</span> <span class="phone-number">{{orderDetails?.phone || '----'}}</span></p>
        </div>
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.createdOn">
            <p class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.CREATED_ON' | translate}}:</span> {{getDateFormat(orderDetails?.createdOn)}} {{getDateTimeFormat(orderDetails?.createdOn)}}</p>
        </div>
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.expectedDeliveryDate">
            <p class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.ESTIMATED_DELIVERY_DATE' | translate}}:</span> {{getDateFormat(orderDetails?.expectedDeliveryDate)}}</p>
        </div>
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.deliveryDate">
            <p class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.DELIVERY_DATE' | translate}}:</span> {{getDateFormat(orderDetails?.deliveryDate)}}</p>
        </div>
        <div class="col-md-6 mb-3">
            <p class="mb-0" *ngIf="orderDetails?.noteToSeller"><span class="font-weight-boldest mr-3">{{'INPUTS.YOUR_NOTES' | translate}}:</span> {{orderDetails?.noteToSeller || '----'}}</p>
            <p class="mb-0" *ngIf="orderDetails?.noteToBuyer"><span class="font-weight-boldest mr-3">{{'INPUTS.SUPPLIER_NOTES' | translate}}:</span> {{orderDetails?.noteToBuyer || '----'}}</p>
        </div>
        <div class="col-md-6 mb-3" *ngIf="orderDetails?.branchName">
            <p class="mb-0"><span class="font-weight-boldest mr-3">{{'INPUTS.BRANCH' | translate}}:</span> {{orderDetails?.branchName || '----'}}</p>
        </div>
        <div class="col-md-12 mb-3" *ngIf="orderDetails?.shippingAddress?.cityName">
            <p class="font-weight-boldest mb-1">{{'INPUTS.CUSTOMER_SHIPPING_ADDRESS' | translate}}:</p>
            <div class="border mb-3 px-4 pt-4">
                <div class="row">
                    <div class="col-md-6">
                        <p class="mb-2"><span class="font-weight-boldest mb-3">{{'INPUTS.NAME' | translate}}:</span> {{orderDetails?.shippingAddress?.firstName}} {{orderDetails?.shippingAddress?.lastName}}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-2"><span class="font-weight-boldest mb-3">{{'INPUTS.PHONE_NUMBER' | translate}}:</span> {{orderDetails?.shippingAddress?.phoneNumber || '----'}}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-2"><span class="font-weight-boldest mb-3">{{'INPUTS.ADDRESS_LINE_1' | translate}}:</span> {{orderDetails?.shippingAddress?.addressLine1 || '----'}}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-2"><span class="font-weight-boldest mb-3">{{'INPUTS.ADDRESS_LINE_2' | translate}}:</span> {{orderDetails?.shippingAddress?.addressLine2 || '----'}}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-0"><span class="font-weight-boldest mb-3">{{'INPUTS.CITY' | translate}}:</span> {{orderDetails?.shippingAddress?.cityName || '----'}}</p>
                    </div>
                </div>
                <br>
            </div>        </div>
        <div class="col-12 mb-0">
            <p class="font-weight-boldest mt-3 mb-0">{{'INPUTS.ITEMS_LIST' | translate}}</p>
            <table class="table table-bordered print-friendly">
                <tbody>
                    <tr>
                        <td class="text-left font-weight-boldest">{{'INPUTS.ITEM_NAME' | translate}}</td>
                        <td class="text-center font-weight-boldest">{{'INPUTS.QUANTITY' | translate}}</td>
                        <td class="text-center font-weight-boldest">{{'INPUTS.UNIT_PRICE' | translate}} ({{orderDetails?.currencyName || '----'}})</td>
                        <td class="text-center font-weight-boldest">{{'TABLE.DISCOUNT' | translate}}</td>
                        <td class="text-center font-weight-boldest">{{'TABLE.PRICE_AFTER_DISCOUNT' | translate}}</td>
                        <td class="text-center font-weight-boldest">{{'TABLE.TAX' | translate}} ({{orderDetails?.orderDetailItems[0]?.tax}})%</td>
                        <td class="text-center font-weight-boldest">{{'INPUTS.LINE_TOTAL' | translate}} ({{orderDetails?.currencyName || '----'}})</td>
                    </tr>
                    <tr  *ngFor="let item of orderDetails?.orderDetailItems">
                        <td class="text-left">{{item?.productName || '----'}}, {{item?.productDescription}}</td>
                        <td class="text-center">{{item?.quantity}}</td>
                        <td class="text-center">{{(item?.preTaxPrice).toFixed(2) || '----'}}</td>
                        <td class="text-center">{{(item?.unitPriceDiscount).toFixed(2) || '----'}}</td>
                        <td class="text-center">{{(item?.preTaxPrice - item?.unitPriceDiscount).toFixed(2) || '----'}}</td>
                        <td class="text-center">{{(((item?.preTaxPrice - item?.unitPriceDiscount) * (item?.tax/100)) * item?.quantity)?.toFixed(2)}}</td>
                        <td class="text-center">{{item?.postTaxPriceLineTotalAfterDiscount?.toFixed(2) || '----'}}</td>
                    </tr>
                    <tr>
                        <td class="text-right font-weight-boldest no-border">{{'INPUTS.TOTAL_QUANTITIES' | translate}}</td>
                        <td class="text-center">{{getTotalQuantity(orderDetails?.orderDetailItems)}}</td>
                        <td class="text-right font-weight-boldest no-border" colspan="4">{{'INPUTS.TOTAL_DUE_AMOUNT' | translate}}</td>
                        <td class="text-center">{{(orderDetails?.totalDueAmount.toFixed(2) + ' ' + orderDetails?.currencyName) || '----'}}</td>
                    </tr>
                </tbody>
            </table>
            <p>{{'INPUTS.TAX_VALUE' | translate}} {{orderDetails?.totalLineTaxAmount?.toFixed(2)}} {{orderDetails?.currencyName}}</p>
        </div>
    </div>
</ng-template>