<ul class="mobile-links mobile-links--level--{{ level }}" appCollapse>
    <li *ngFor="let link of links">
        <div *ngIf="['link', 'button'].includes(link.type) && (link.label !== 'MY_ACCOUNT' || checkIfLogin())" class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
            <div class="mobile-links__item-title">
                <a *ngIf="link.type === 'link'" class="mobile-links__item-link" (click)="onItemClick(link)" [routerLink]="link.url">{{ 'LINKS.'+link.label | translate }}</a>
                <a *ngIf="link.type !== 'link'" class="mobile-links__item-link" (click)="onItemClick(link)">
                    <span *ngIf="link?.data?.flag" class="w-25 mx-2">
                        <img class="flag" [src]="link?.data?.flag" alt="language">
                    </span>
                    {{ 'LINKS.'+link.label | translate }}
                </a>
                <button *ngIf="link.children?.length" class="mobile-links__item-toggle" type="button" (click)="item.toggle()">
                    <app-icon class="mobile-links__item-arrow" name="arrow-rounded-down-12x7" size="12x7"></app-icon>
                </button>
            </div>
            <div *ngIf="link.children?.length" class="mobile-links__item-sub-links" appCollapseContent>
                <app-mobile-links [links]="link.children" [level]="level + 1" (itemClick)="itemClick.emit($event)"></app-mobile-links>
            </div>
        </div>
        <div *ngIf="link.type === 'divider'" class="mobile-links__divider"></div>
    </li>
</ul>
