<!-- .departments -->
<div class="departments">
    <div class="departments__body" #bodyElement>
        <div class="departments__links-wrapper" (mouseleave)="onMouseLeave()">
            <div class="departments__submenus-container" #containerElement>
                <ng-container *ngFor="let item of items">
                    <div
                        *ngIf="item.menu && item.menu.type == 'megamenu'"
                        class="departments__submenu departments__submenu--type--megamenu departments__submenu--size--{{ item.menu.size }}"
                        [ngClass]="{'departments__submenu--open': item === hoveredItem}"
                        #submenuElement
                    >
                        <app-header-megamenu [menu]="item.menu" (itemClick)="onSubItemClick()"></app-header-megamenu>
                    </div>
                    <div
                        *ngIf="item.menu && item.menu.type == 'menu'"
                        class="departments__submenu departments__submenu--type--menu"
                        [ngClass]="{'departments__submenu--open': item === hoveredItem}"
                        #submenuElement
                    >
                        <app-header-menu [items]="item.menu.items" (itemClick)="onSubItemClick()"></app-header-menu>
                    </div>
                </ng-container>
            </div>
            <ul class="departments__links">
                <li
                    *ngFor="let item of items"
                    class="departments__item"
                    [ngClass]="{
                        'departments__item--menu': item.menu && item.menu.type == 'menu',
                        'departments__item--hover': item === hoveredItem
                    }"
                    (mouseenter)="onItemMouseEnter(item)"
                    #itemElement
                >

                    <ng-template #link>
                        {{ item.label }}
                        <app-icon *ngIf="item.menu" class="departments__item-arrow" name="arrow-rounded-right-6x9" size="6x9"></app-icon>
                    </ng-template>

                    <a *ngIf="!item.external && item.url.includes('/products')" class="departments__item-link" [routerLink]="['/products']" [queryParams]="{ categorySlug: item.slug, categoryName: item.label}" [target]="item.target" (click)="onItemClick()" (appTouchClick)="onTouchClick($event, item)">
                        <ng-container [ngTemplateOutlet]="link"></ng-container>
                    </a>
                    <a *ngIf="!item.external && item.url.includes('/services')" class="departments__item-link" [routerLink]="['/services']" [queryParams]="{ categorySlug: item.slug, categoryName: item.label}" [target]="item.target" (click)="onItemClick()" (appTouchClick)="onTouchClick($event, item)">
                        <ng-container [ngTemplateOutlet]="link"></ng-container>
                    </a>
                    <a *ngIf="item.external" class="departments__item-link" [href]="item.url" [target]="item.target" (click)="onItemClick()" (appTouchClick)="onTouchClick($event, item)">
                        <ng-container [ngTemplateOutlet]="link"></ng-container>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <button class="departments__button" (click)="toggle()">
        <app-icon class="departments__button-icon" name="menu-18x14" size="18x14"></app-icon>
        {{'INPUTS.SHOP_BY_CATEGORY' | translate}}
        <app-icon class="departments__button-arrow" name="arrow-rounded-down-9x6" size="9x6"></app-icon>
    </button>
</div>
<!-- .departments / end -->
