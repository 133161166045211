<div class="mobile-header" [ngClass]="{'mobile-header--sticky': stickyMode}" #element>
    <div class="mobile-header__panel" #panelElement>
        <div class="container">
            <div class="mobile-header__body">
                <button class="mobile-header__menu-button" (click)="menu.open()">
                    <app-icon name="menu-18x14" size="18x14"></app-icon>
                </button>
                <a class="mobile-header__logo w-30px" [routerLink]="root.home()">
                    <img src="../../../../../assets/images/logos/logo.svg" alt="Growthway">
                </a>

                <app-search
                    appDropdown="mobile-header__search--open"
                    class="mobile-header__search"
                    location="mobile-header"
                    (closeButtonClick)="searchDropdown.close()"
                    #search="search"
                    #searchDropdown="appDropdown"
                ></app-search>

                <div class="mobile-header__indicators">
                    <div class="indicator indicator--mobile-search indicator--mobile d-md-none">
                        <button class="indicator__button" (click)="searchDropdown.open(); search.inputElement.focus()">
                            <span class="indicator__area">
                                <app-icon name="search-20" size="20"></app-icon>
                            </span>
                        </button>
                    </div>

                    <div class="indicator indicator--mobile">
                        <a [routerLink]="root.wishlist()" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="heart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ wishlist.count$|async }}</span>
                            </span>
                        </a>
                    </div>
                    
                    <div class="indicator indicator--mobile">
                        <a routerLink="/rfq" class="indicator__button">
                            <span class="indicator__area">
                                <i class="far fa-paper-plane"></i>
                                <span class="indicator__value">{{ cart.rfqCount$|async }}</span>
                            </span>
                        </a>
                    </div>

                    <div class="indicator indicator--mobile">
                        <a [routerLink]="root.cart()" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="cart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ cart.quantity$|async }}</span>
                            </span>
                        </a>
                    </div>

                    <div class="indicator indicator--mobile">
                        <a [routerLink]="['/auth/notifications']" class="indicator__button">
                            <span class="indicator__area">
                                <i class="far fa-bell" size="26"></i>
                                <span class="indicator__value">{{ cart.notificationsCount$|async }}</span>
                            </span>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
