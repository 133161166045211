<ul class="nav-links__list">
    <ng-container *ngFor="let item of items">
        <li
            *ngIf="item.label !== 'MY_COMPANIES' && item.label !== 'BE_SUPPLIER'"
            class="nav-links__item"
            [ngClass]="{
                'nav-links__item--has-submenu': item.menu,
                'nav-links__item--hover': hoveredItem === item
            }"
            (mouseenter)="onItemMouseEnter(item)"
            (mouseleave)="onItemMouseLeave(item)"
            (appOutsideTouchClick)="onOutsideTouchClick(item)"
            #itemElement
        >
    
            <ng-template #link>
                <div class="nav-links__item-body">
                    {{ 'LINKS.'+item.label | translate }}
                    <app-icon *ngIf="item.menu" class="nav-links__item-arrow" name="arrow-rounded-down-9x6" size="9x6"></app-icon>
                </div>
            </ng-template>
    
            <a *ngIf="!item.external" class="nav-links__item-link" [routerLink]="item.url" [target]="item.target" (appTouchClick)="onTouchClick($event, item)">
                <ng-container [ngTemplateOutlet]="link"></ng-container>
            </a>
            <a *ngIf="item.external" class="nav-links__item-link" [href]="item.url" [target]="item.target" (appTouchClick)="onTouchClick($event, item)">
                <ng-container [ngTemplateOutlet]="link"></ng-container>
            </a>
    
            <div
                *ngIf="item.menu && item.menu.type == 'menu'"
                class="nav-links__submenu nav-links__submenu--type--menu"
                [ngClass]="{'nav-links__submenu--open': hoveredItem === item}"
                #submenuElement
            >
                <app-header-menu [items]="item.menu.items" (itemClick)="onSubItemClick()"></app-header-menu>
            </div>
            <div
                *ngIf="item.menu && item.menu.type == 'megamenu'"
                class="nav-links__submenu nav-links__submenu--type--megamenu nav-links__submenu--size--{{ item.menu.size }}"
                [ngClass]="{'nav-links__submenu--open': hoveredItem === item}"
                #submenuElement
            >
                <app-header-megamenu [menu]="item.menu" (itemClick)="onSubItemClick()"></app-header-megamenu>
            </div>
        </li>
        <li
            *ngIf="item.label === 'MY_COMPANIES' && checkIflogin() && (testEmitter$ | async)"
            class="nav-links__item"
            [ngClass]="{
                'nav-links__item--has-submenu': item.menu,
                'nav-links__item--hover': hoveredItem === item
            }"
            (mouseenter)="onItemMouseEnter(item)"
            (mouseleave)="onItemMouseLeave(item)"
            (appOutsideTouchClick)="onOutsideTouchClick(item)"
            #itemElement
        >
    
            <ng-template #link>
                <div class="nav-links__item-body">
                    {{ 'LINKS.'+item.label | translate }}
                    <app-icon *ngIf="item.menu" class="nav-links__item-arrow" name="arrow-rounded-down-9x6" size="9x6"></app-icon>
                </div>
            </ng-template>
    
            <a class="nav-links__item-link" [routerLink]="item.url" [target]="item.target" (appTouchClick)="onTouchClick($event, item)">
                <ng-container [ngTemplateOutlet]="link"></ng-container>
            </a>
        </li>
        <li
            *ngIf="item.label === 'BE_SUPPLIER' && (!(testEmitter$ | async) || !checkIflogin())"
            class="nav-links__item"
            [ngClass]="{
                'nav-links__item--has-submenu': item.menu,
                'nav-links__item--hover': hoveredItem === item
            }"
            (mouseenter)="onItemMouseEnter(item)"
            (mouseleave)="onItemMouseLeave(item)"
            (appOutsideTouchClick)="onOutsideTouchClick(item)"
            #itemElement
        >
    
            <ng-template #link>
                <div class="nav-links__item-body">
                    {{ 'LINKS.'+item.label | translate }}
                    <app-icon *ngIf="item.menu" class="nav-links__item-arrow" name="arrow-rounded-down-9x6" size="9x6"></app-icon>
                </div>
            </ng-template>
    
            <a class="nav-links__item-link" [routerLink]="item.url" [target]="item.target" (appTouchClick)="onTouchClick($event, item)">
                <ng-container [ngTemplateOutlet]="link"></ng-container>
            </a>
        </li>
    </ng-container>
</ul>
