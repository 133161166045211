<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
    <button class="product-card__quickview" type="button" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
    </button>

    <div class="product-card__badges-list">
        <div *ngIf="product.compareAtPrice" class="product-card__badge product-card__badge--sale">{{'HINTS.SALE' | translate}}</div>
        <div *ngIf="product.badges.includes('hot')" class="product-card__badge product-card__badge--hot">Hot</div>
        <div *ngIf="product.badges.includes('new')" class="product-card__badge product-card__badge--new">New</div>
    </div>

    <div class="product-card__image product-image px-2">
        <a appClick routerLink="/products/{{product.slug}}" *ngIf="product.images?.length" class="product-image__body">
            <img class="product-image__img" [src]="product.imagePath" alt="">
        </a>
    </div>
    <div class="product-card__info px-2">
        <div class="product-card__name">
            <a appClick routerLink="/products/{{product.slug}}">{{ product.name }}</a>
            <p class="small-text mb-1">{{ product.description }}</p>
            <div class="d-flex align-items-center">
                <span class="w-25px">
                    <img class="w-100" [src]="product.countryFlag" alt="{{ product.originCountry }}">
                </span>
                <p class="mb-0 mx-2 small-text product-card-company-name">{{ product.originCountry }}</p>
            </div>
        </div>
        <div class="my-2 d-flex align-items-center">
            <span class="cyrcle h-25px w-25px overflow-hidden border align-items-center d-flex">
                <img class="w-100" [src]="product.companyLogo" alt="{{ product.companyName }}">
            </span>
            <a class="mx-2 small-text product-card-company-name" appClick routerLink="/companies/{{ product?.companySlug }}">{{ product.companyName }}</a>
        </div>
        <!-- <div class="product-card__rating">
            <app-rating [value]="product.rating"></app-rating>
            <div class="product-card__rating-legend">{{ product.reviews }} Reviews</div>
        </div> -->
        <ul *ngIf="featuredAttributes.length" class="product-card__features-list">
            <li *ngFor="let attribute of featuredAttributes">
                {{ attribute.name }}:
                <ng-container *ngFor="let value of attribute.values; let last = last">
                    {{ value.name }}<ng-container *ngIf="!last">, </ng-container>
                </ng-container>
            </li>
        </ul>
    </div>
    <div class="product-card__actions px-2">
        <!-- <div class="product-card__availability">
            Availability:
            <span *ngIf="product.availability === 'in-stock'" class="text-success">In Stock</span>
        </div> -->
        <div class="product-card__prices">
            <ng-container *ngIf="product.compareAtPrice && !product.hiddenPrice">
                <span class="product-card__new-price">{{ product.price.toFixed(2) }} <span class="product-currency">{{product.currency}}</span></span>{{ ' ' }}
                <span class="product-card__old-price">{{ product.compareAtPrice.toFixed(2) }} <span class="product-currency">{{product.currency}}</span></span>
            </ng-container>
            <ng-container *ngIf="!product.compareAtPrice && !product.hiddenPrice">
                {{ product.price.toFixed(2) }} <span class="product-currency">{{product.currency}}</span>
            </ng-container>
            <ng-container *ngIf="product?.unitOfMeasurement && !product.hiddenPrice">
                <span class="small-text"> / {{ product?.unitOfMeasurement }}</span>
            </ng-container>
        </div>
        <div class="product-card__buttons">
            <div class="d-flex">
                <app-input-number aria-label="Quantity" class="mx-1 product__quantity w-100" size="md" [min]="1" [formControl]="quantity"></app-input-number>
                <button *ngIf="!product.hiddenPrice" class="mx-1 px-1 btn btn-primary product-card__addtocart w-100" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">{{'INPUTS.ADD_TO_CART' | translate}}</button>
                <button *ngIf="product.hiddenPrice"class="mx-1 px-1 btn btn-warning product-card__addtocart w-100" type="button" appClick (click)="addRfq()" [ngClass]="{'btn-loading': addingToRfq}">{{'INPUTS.REQUEST_FOR_QUOTATION' | translate}}</button>
            </div>
            <!-- <button class="btn btn-secondary product-card__addtocart product-card__addtocart--list" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">Add To Cart</button> -->
            <button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist" type="button" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">
                <app-icon name="wishlist-16" size="16"></app-icon>
                <span class="fake-svg-icon fake-svg-icon--wishlist-16"></span>
            </button>
            <!-- <button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare" type="button" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">
                <app-icon name="compare-16" size="16"></app-icon>
                <span class="fake-svg-icon fake-svg-icon--compare-16"></span>
            </button> -->
        </div>
    </div>
</div>
