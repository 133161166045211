<div class="account-menu">
    <ng-container *ngIf="notifications?.length">
        <ul class="account-menu__links notifications-list p-0">
            <li *ngFor="let notification of notifications" [ngClass]="{ 'not-seen': !notification?.read }" class="d-flex align-items-center position-relative">
                <a [routerLink]="getLink(notification).url" [queryParams]="getLink(notification).queryParams" (click)="closeMenu.emit(); readNotification(notification?.id)" class="account-menu__user d-flex flex-fill">
                    <div *ngIf="notification?.imageUri" class="account-menu__user-avatar">
                        <img class="h-100" alt="{{notification?.header}}" [src]="notification?.imageUri">
                    </div>
                    <div class="account-menu__user-info flex-fill">
                        <h6 class="notification-header mb-1">{{notification?.header}}</h6>
                        <p class="account-menu__user-email small-text mb-0">{{notification?.body}}</p>
                    </div>
                </a>
                <button (click)="deleteNotification(notification?.id)" class="btn btn-default btn-sm px-1 small-text outline-0 delete-notification-btn">
                    <i class="fa fa-trash c-red"></i>
                </button>
            </li>
        </ul>
        <div class="account-menu__divider"></div>
        <div class="p-3 text-center" *ngIf="totalPages > pageNumber">
            <button class="btn-link" (click)="seeMore()">{{'INPUTS.SEE_MORE' | translate}}</button>
        </div>
    </ng-container>
    <ng-container *ngIf="!notifications?.length">
        <h6 class="text-center pt-4">{{'HINTS.YOUR_NOTIFICATIONS_LIST_IS_EMPTY' | translate}}</h6>
    </ng-container>
</div>
