<div class="block block--highlighted block-categories block-categories--layout--{{ layout }}">
    <div class="container">
        <app-block-header [header]="header" [link]="'/companies/companies-page'"></app-block-header>

        <div class="block-categories__list">
            <div *ngFor="let company of companies" class="block-categories__item category-card category-card--layout--{{ layout }}">
                <div class="category-card__body">
                    <div class="d-flex w-100 align-items-center">
                        <div class="category-card__image">
                            <a routerLink="/companies/{{ company.slug }}"><img [src]="company.imagePath" [alt]="company.name"></a>
                        </div>
                        <div class="category-card__name">
                            <a routerLink="/companies/{{ company.slug }}">{{ company.name }}</a>
                        </div>
                    </div>
                    <div class="category-card__content">
                        <div class="category-card__links">
                            <p>{{ company?.description }}</p>
                        </div>
                    </div>
                    <div class="category-card__all d-flex">
                        <a class="flex-fill" routerLink="/companies/{{ company.slug }}">{{'LINKS.SHOW_COMPANY' | translate}}</a>
                        <a *ngIf="company?.businessType === 1 || company?.businessType === 3" [routerLink]="['/products']" [queryParams]="{ companySlug: company?.slug, companyName: company?.name }">{{'INPUTS.SHOW_ALL_PRODUCTS' | translate}}</a>
                        <a *ngIf="company?.businessType === 2 || company?.businessType === 3" [routerLink]="['/services']" [queryParams]="{ companySlug: company?.slug, companyName: company?.name }">{{'INPUTS.SHOW_ALL_SERVICES' | translate}}</a>
                    </div>
                </div>
            </div>
            <!-- <div class="text-center w-100 mt-4">
                <a routerLink="/companies/companies-page">{{'LINKS.SHOW_ALL_COMPANIES' | translate}}</a>
            </div> -->
        </div>
    </div>
</div>