import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { CartService } from 'src/app/shared/services/cart.service';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    userD: Subject<any> = new Subject();
    private userDetails: any;

    set currentUserDetails(index: any) {
        this.userDetails = index;
        this.userD.next(index);
    }

    get currentUserDetails() {
        return this.userDetails;
    }

    constructor(private router: Router, private httpClient: HttpClient,public cart: CartService,) {}

    login(email: string, password: string): Observable<any> {
        return this.httpClient.post(`Account/Login`, {
            email,
            password,
        });
    }

    register(
        email: string,
        name: string,
        companyName: string,
        countryId: string,
        phoneNumber: string,
        password: string,
        confirmPassword: string,
        isCustomer: boolean
    ): Observable<any> {
        return this.httpClient.post(`Account/Register`, {
            email,
            name,
            companyName,
            countryId,
            phoneNumber,
            password,
            confirmPassword,
            isCustomer,
        });
    }

    // confirmEmail(userId:string,code:string): Observable<any> {
    //   return this.httpClient.post(
    //     `Account/ConfirmEmail`,
    //     {
    //       userId,
    //       code,
    //     }
    //   );
    // }

    forgetPassword(email: string): Observable<any> {
        return this.httpClient.post(`Account/ForgotPassword`, {
            email,
        });
    }

    resetPassword(data): Observable<any> {
        return this.httpClient.post(`Account/ResetPassword`, data);
    }
    // /api/store/Account/ResetPassword
    // resetPassword(
    //     email: string,
    //     password: string,
    //     confirmPassword: string,
    //     code: string
    // ): Observable<any> {
    //     return this.httpClient.post(`Account/ResetPassword`, {
    //         email,
    //         password,
    //         confirmPassword,
    //         code,
    //     });
    // }

    logout() {
        localStorage.removeItem('token');
        this.router.navigate(['/auth/login']);
        this.cart.getCustomerCartCount();
        this.cart.getCustomerRfqCount();
    }

    confirmEmail(data): Observable<any> {
        return this.httpClient.post(`Account/ConfirmEmail`, data);
    }

    getUserByToken(): Observable<any> {
        return this.httpClient.get<any>(`Manage/GetUser`);
    }

    getCustomerUser(): Observable<any> {
        return this.httpClient.get<any>(`Manage/GetCustomerUser`);
    }

    updateCustomerUserProfile(data): Observable<any> {
        return this.httpClient.put('Manage/UpdateCustomerUserProfile', data);
    }

    changePassword(model): Observable<any> {
        return this.httpClient.post('Manage/ChangePassword', model);
    }

    getShippingAddresses(): Observable<any> {
        return this.httpClient.post('ShippingAddresses/GetShippingAddresses', {rowsPerPage:-1});
    }

    createShippingAddress(model): Observable<any> {
        return this.httpClient.post('ShippingAddresses/CreateShippingAddress', model);
    }

    getFullAddressName(lat, lng) {
        return this.httpClient.get<any>(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDGY4ZEEgNDbOWPz4riWbb38AB6L-GgBic&language=ar`
        );
    }

    getShippingAddress(data): Observable<any> {
        return this.httpClient.post('ShippingAddresses/GetShippingAddress', data);
    }

    updateShippingAddress(model): Observable<any> {
        return this.httpClient.put('ShippingAddresses/UpdateShippingAddress', model);
    }

    deleteShippingAddress(id): Observable<any> {
        return this.httpClient.delete(`ShippingAddresses/DeleteShippingAddress?id=${id}`);
    }

    getCustomerOrders(data): Observable<any> {
        return this.httpClient.post('CustomerOrders/GetCustomerOrders', data);
    }

    getCustomerOrder(data): Observable<any> {
        return this.httpClient.post('CustomerOrders/GetCustomerOrder', data);
    }

    updateCustomerOrder(data): Observable<any> {
        return this.httpClient.put('CustomerOrders/UpdateCustomerOrder', data);
    }

    reOrder(data): Observable<any> {
        return this.httpClient.post('CustomerOrders/ReOrder', data);
    }

}
