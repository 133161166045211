<div class="site-footer">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <app-footer-contacts></app-footer-contacts>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="{{'TITLES.INFORMATION' | translate}}" [links]="[
                        {label: 'LINKS.ABOUTUS' | translate,             url: '/site/about-us'},
                        {label: 'LINKS.TERMS_AND_CONDITIONS' | translate,       url: '/site/terms'},
                        {label: 'LINKS.CONTACTUS' | translate,           url: '/site/contact-us'}
                    ]"></app-footer-links>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="{{'LINKS.MY_ACCOUNT' | translate}}" [links]="[
                        {label: 'LINKS.ORDER_HISTORY' | translate,  url: '/auth/orders'},
                        {label: 'LINKS.WISH_LIST' | translate,      url: '/shop/wishlist'}
                    ]"></app-footer-links>
                </div>
                <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div>
            </div>
        </div>

        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                {{'HINTS.RIGHTS_RESERVED' | translate}}
            </div>
            <div class="site-footer__payments">
                <!-- <img src="assets/images/payments.png" alt=""> -->
            </div>
        </div>
    </div>
    <app-totop></app-totop>
</div>