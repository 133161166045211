import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { HeaderService } from '../../header.service';
import { CartService } from 'src/app/shared/services/cart.service';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss']
})
export class NotificationsMenuComponent {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    @Input() changes: boolean = null;
    notifications:any[];
    pageNumber:number = 1;
    isSeeMore:boolean = false;
    totalPages:number = 1;

    constructor(private headerService:HeaderService, public cart: CartService,public loaderService:LoaderService) { }

    ngOnInit(): void {
      this.getUserNotifications();
    }

    ngOnChanges() {
      if(this.changes) {
        this.pageNumber = 1;
        this.getUserNotifications();
      }
    }

    getUserNotifications() {
      this.loaderService.setIsLoading = true;
      let dataFilter = {
        "pageNumber": this.pageNumber,
        "rowsPerPage": 4,
      }
      this.headerService.getUserNotifications(dataFilter).subscribe((data) => {
        this.cart.getUserNotificationsCount();
        this.totalPages = data.result.notifications.totalPages;
        if(this.isSeeMore) {
          this.notifications = this.notifications.concat(data.result.notifications.items);
        }
        else {
          this.notifications = data.result.notifications.items;
        }
        this.isSeeMore = false;
        this.loaderService.setIsLoading = false;
      }, (error) => {
        this.loaderService.setIsLoading = false;
      });
    }

    readNotification(id) {
      if(!this.notifications?.find(item => item.id === id)?.read) {
        this.pageNumber = 1;
        this.headerService.readNotification({id:id}).subscribe(() => {
          this.getUserNotifications();
        });
      }
    }

    seeMore() {
      this.pageNumber++;
      this.isSeeMore = true;
      this.getUserNotifications();
    }

    getLink(notification) {
      if(notification.type === 'Order') {
        return {url: '/auth/orders/view/'+notification.orderId, queryParams: {}}
      }
      if(notification.type === 'Rfq-Approved By Seller') {
        return {url: '/rfq', queryParams: {activeTab: 2}};
      }
      if(notification.type === 'Rfq-Rejected By Seller' || notification.type === 'Rfq-Rejected By Buyer' || notification.type === 'Rfq-Ordered') {
        return {url: '/rfq', queryParams: {activeTab: 3}};
      }
      if(notification.type === 'Other') {
        if(notification.productSlug) {
          return {url: '/products/'+notification.productSlug, queryParams: {}};
        }
        if(notification.companySlug) {
          return {url: '/companies/'+notification.companySlug, queryParams: {}};
        }
        // if(notification.categorySlug) {
        //   return {url: '/rfq'+notification.categorySlug, queryParams: {}};
        // }
      }
    }

    deleteNotification(notificationId) {
      this.loaderService.setIsLoading = true;
      this.headerService.deleteNotification({id:notificationId}).subscribe(() => {
        setTimeout(() => {
          this.getUserNotifications();
        },1000);
      }, (error) => {
        this.loaderService.setIsLoading = false;
      });
    }
}
