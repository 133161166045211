<div class="account-menu">
    <form class="account-menu__form" [formGroup]="loginForm" (ngSubmit)="submit()" *ngIf="!checkIfLogin()">
        <div class="account-menu__form-title">{{'TITLES.LOGIN_TO_YOUR_ACCOUNT' | translate}}</div>
        <div class="form-group">
            <label class="sr-only">{{'INPUTS.EMAIL' | translate}}</label>
            <input type="email" class="form-control form-control-sm" placeholder="{{'INPUTS.EMAIL' | translate}}" formControlName="email" [ngClass]="{ 'is-invalid': loginForm?.controls['email']?.invalid && (loginForm?.controls['email']?.dirty || loginForm?.controls['email']?.touched) }" required>
            <span *ngIf="(loginForm?.controls['email']?.dirty || loginForm?.controls['email']?.touched) && loginForm?.controls['email']?.errors?.required" class="invalid-feedback">
                {{'VALIDATIONS.email_required' | translate}}
            </span>
            <span *ngIf="(loginForm?.controls['email']?.dirty || loginForm?.controls['email']?.touched) && loginForm?.controls['email']?.errors?.email" class="invalid-feedback">
                {{'VALIDATIONS.email_email' | translate}}
            </span>
        </div>
        <div class="form-group mb-0">
            <label class="sr-only">{{'INPUTS.PASSWORD' | translate}}</label>
            <div class="account-menu__form-forgot">
                <input type="password" class="form-control form-control-sm" placeholder="{{'INPUTS.PASSWORD' | translate}}" formControlName="password" [ngClass]="{'is-invalid': loginForm?.controls['password']?.invalid && (loginForm?.controls['password']?.dirty || loginForm?.controls['password']?.touched) }">
                <span *ngIf="loginForm?.controls['password']?.errors?.required && (loginForm?.controls['password']?.dirty || loginForm?.controls['password']?.touched)" class="invalid-feedback">
                    {{'VALIDATIONS.password_required' | translate}}
                </span>
            </div>
        </div>
        <a class="small-text" routerLink="/auth/forget-password" (click)="closeMenu.emit()">{{'HINTS.FORGET_YOUR_PASSWORD' | translate}}</a>
        <div class="form-group account-menu__form-button">
            <button [disabled]="loginForm?.invalid" type="submit" class="btn btn-primary btn-sm">{{'LINKS.LOGIN' | translate}}</button>
        </div>
        <div class="account-menu__form-link">
            <a routerLink="/auth/register" (click)="closeMenu.emit()">
                {{'LINKS.CREATE_AN_ACCOUNT' | translate}}
            </a>
        </div>
    </form>
    <div class="account-menu__divider"></div>
    <ng-container *ngIf="checkIfLogin()">
        <a routerLink="/auth/profile" class="account-menu__user" (click)="closeMenu.emit()">
            <div class="account-menu__user-avatar">
                <img class="h-100" [src]="user?.avatarUri || 'assets/images/avatars/avatar-3.jpg'" alt="{{user?.fullName}}">
            </div>
            <div class="account-menu__user-info">
                <div class="account-menu__user-name">{{user?.fullName}} <i *ngIf="user?.isVerified" class="fas fa-check-circle c-green"></i></div>
                <div class="account-menu__user-email">{{user?.email}}</div>
                <div class="account-menu__user-email">{{user?.phoneNumber}}</div>
                <div *ngIf="!user?.isVerified" class="c-red small-text">{{'HINTS.PRESS_HERE_TO_BE_VERIFIED' | translate}}</div>
            </div>
        </a>
        <div class="account-menu__divider"></div>
        <ul class="account-menu__links">
            <li><a routerLink="/auth/profile" (click)="closeMenu.emit()">{{'LINKS.EDIT_PROFILE' | translate}}</a></li>
            <li><a routerLink="/auth/password" (click)="closeMenu.emit()">{{'LINKS.PASSWORD' | translate}}</a></li>
            <li><a routerLink="/auth/addresses" (click)="closeMenu.emit()">{{'LINKS.ADDRESSES' | translate}}</a></li>
            <li><a routerLink="/auth/orders" (click)="closeMenu.emit()">{{'LINKS.ORDER_HISTORY' | translate}}</a></li>
            <li><a routerLink="/rfq" (click)="closeMenu.emit()">{{'LINKS.MY_RFQ' | translate}}</a></li>
            <li><a routerLink="/auth/orders/old" (click)="closeMenu.emit()">{{'LINKS.OLD_ORDERS' | translate}}</a></li>
            <li><a routerLink="/auth/notifications" (click)="closeMenu.emit()">{{'LINKS.NOTIFICATIONS' | translate}}</a></li>
        </ul>
        <div class="account-menu__divider"></div>
        <ul class="account-menu__links">
            <li><a routerLink="/auth/login" (click)="logout();closeMenu.emit()">{{'LINKS.LOGOUT' | translate}}</a></li>
        </ul>
    </ng-container>
</div>
