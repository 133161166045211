<!-- .block-slideshow -->
<div class="block-slideshow block" [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}">
    <div class="container">
        <div class="row">
            <div *ngIf="withDepartments" class="col-3 d-lg-block d-none" appDepartmentsArea></div>
            <div class="col-12" [ngClass]="{'col-lg-12': !withDepartments, 'col-lg-9': withDepartments}">
                <div class="block-slideshow__body">
                    <owl-carousel-o *ngIf="slides?.length" [options]="options" appOwlPreventClick>
                        <ng-template *ngFor="let slide of slides" carouselSlide>
                            <a class="block-slideshow__slide" href="{{slide.link}}" target="_blank" appClick>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop" [ngStyle]="{'background-image': 'url('+(withDepartments ? slide.imageSiteUrl : slide.imageSiteUrl)+')'}"></div>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile" [ngStyle]="{'background-image': 'url('+slide.imageSiteUrl+')'}"></div>
                                <!-- <div class="block-slideshow__slide-content">
                                    <div class="block-slideshow__slide-title" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.title)"></div>
                                    <div class="block-slideshow__slide-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.description)"></div>
                                    <div class="block-slideshow__slide-button">
                                        <span class="btn btn-primary btn-lg">Shop Now</span>
                                    </div>
                                </div> -->
                            </a>
                            <!-- <div *ngIf="!slide?.link" class="block-slideshow__slide" >
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop" [ngStyle]="{'background-image': 'url('+(withDepartments ? slide.imageSiteUrl : slide.imageSiteUrl)+')'}"></div>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile" [ngStyle]="{'background-image': 'url('+slide.imageSiteUrl+')'}"></div>
                            </div> -->
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-slideshow / end -->
