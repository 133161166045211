import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.scss']
})
export class PrintInvoiceComponent implements OnChanges {
  @Input() orderDetails:any = null;
  @Input() type:string = 'ORDER';

  constructor(private translate:TranslateService, private loaderService: LoaderService,) { }

  ngOnChanges(e): void {
    if(e.orderDetails.currentValue) {
      this.loaderService.setIsLoading = true;
      setTimeout(() => {
        this.print();
        this.loaderService.setIsLoading = false;
      }, 1000);
    }
  }

  print(): void {
		let printContents, popupWin;
		let rtl = '';
		if(document.getElementById('rtl-file')) {
			rtl = 'body {direction: rtl;}.logo-container h6 {text-align: left;} .text-left{text-align: right;}.text-right{text-align: left;}';
		}
		else {
			rtl = '.logo-container h6 {text-align: right;} .text-left{text-align: left;}.text-right{text-align: right;}';
		}
		printContents = document.getElementById('contentToConvert').innerHTML;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=900px,width=900px');
		popupWin.document.open();
		popupWin.document.write(`
		  <html>
			<head>
			  <title></title>
			  <style>
			  /*** print **/
				@media print {
					${rtl}
					table.print-friendly tr td, table.print-friendly tr th, table.print-friendly tr, table.print-friendly {
						page-break-inside: avoid;
					}
					.row {
						display: flex;
						flex-wrap: wrap;
					}
					.col-md-6 {
						flex: 0 0 50%;
						padding: 0 10px;
						margin-bottom: 1px;
						box-sizing: border-box;
					}
					.col-md-3 {
						flex: 0 0 25%;
						padding: 0 10px;
						margin-bottom: 3px;
						box-sizing: border-box;
					}
					.col-md-11 {
						flex: 0 0 100%;
						padding: 0 10px;
						margin-bottom: 3px;
						box-sizing: border-box;
					}
					.col-md-6 p, .col-12 p, .col-md-3 p, .col-md-11 p {
						margin-bottom: 0px;
					}
					.col-12 {
						flex: 0 0 100%;
						margin-bottom: 0px;
					}
					.font-weight-boldest {
						font-weight: 700;
					}
					.company-logo {
						height: 60px;
						display: block;
						margin-bottom: 5px;
					}
					.logo-container {
						display: flex;
						align-items: center;
					}
					.logo-container h6 {
						font-size: 25px;
						font-weight: 700;
						margin: 0 10px;
						flex: 1 1 auto !important;
					}
					.logo-container p {
						margin: 0 10px;
						flex: 1 1 auto !important;
					}
					.table {
						width: 100%;
						margin-bottom: 1rem;
						color: #3F4254;
						background-color: transparent;
					}
					th, td, tr {
						border: 1px solid #444;
						padding: 3px;
						text-align: center;
					}
					.d-none {
						display: none;
					}
					.no-border {
						border: 0;
					}
					input.w-70px {
						display: none;
					}
					input.w-70px + span.d-none {
						display: block;
					}
					.buyer-logo {
						height: 35px;
						margin: 0 10px;
						vertical-align: middle;
					}
					.border {
						border: 1px solid #444;
					}
          .company_logo {
            display: flex;
            align-items: center;
          }
				}
        .phone-number {
          direction: ltr;
          display: inline-block;
        }
				/*** end print **/
			  </style>
			</head>
			<body onload="window.print();window.close()">
			<h5 style="margin-bottom:0!important; text-align:center; line-height: 5px;">${this.translate.instant(`INPUTS.${this.type}_NUMBER`)} ${this.orderDetails?.orderNumber}<h5>
			${printContents}
			</body>
		  </html>`
		);
		popupWin.document.close();
	}

  getTax() {
		return this.orderDetails?.orderDetailItems.map((item) => {
			return (((item?.preTaxPrice - item?.unitPriceDiscount) * (item?.tax/100)) * item.quantity)?.toFixed(2);
		}).reduce((a, b) => Number(a) + Number(b), 0)?.toFixed(2);
	}

  getTotalQuantity(items) {
		return items?.map((item) => {
			return item.quantity
		}).reduce((a, b) => a + b, 0);
	}

  getDateFormat(date) {
		if(!date)
		   return '----';

		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + (d.getDate()),
			year = d.getFullYear();
			
		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
	
		return [ day, month, year,].join('/');
	}

  getDateTimeFormat(date) {
		if(!date)
		   return '----';
		return new Date(date).toLocaleTimeString();
	}
}
