// ARABIC
export const locale = {
  lang: 'ar',
  data: {
    HEADER: {
      LANGUAGE: "اللغة",
      CUSTOMER_SERVICE: "خدمة العملاء"
    },
    LINKS: {
      HOME: "الرئيسية",
      CONTACTUS: "اتصل بنا",
      ABOUTUS: "نبذة عنا",
      COMPANIES: "الموردين",
      MY_COMPANIES: "شركاتي",
      BE_SUPPLIER: "كن مورد",
      PRODUCTS: "المنتجات",
      ALL_PRODUCTS: "كل المنتجات",
      ADDRESSES: "العناوين",
      MY_ACCOUNT: "حسابي",
      NOTIFICATIONS: "الاشعارات",
      WISH_LIST: "قائمة المفضلة",
      CART: "سلة المشتريات",
      CHECKOUT: "الخروج",
      ORDER_HISTORY: "سجل الطلبات",
      OLD_ORDERS: "الطلبات السابقة",
      ADD_COMPANY: "إضافة شركة",
      ADD_ADDRESS: "إضافة عنوان",
      EDIT_COMPANY: "تعديل الشركة",
      EDIT_ADDRESS: "تعديل العنوان",
      PASSWORD: "كلمة المرور",
      LOGOUT: "تسجيل الخروج",
      LOGIN: "تسجيل الدخول",
      REGISTER: "تسجيل",
      SHOW_COMPANY: "عرض الشركة",
      SHOW_ALL: "إظهار الكل",
      SHOW_ALL_COMPANIES: "عرض كل الشركات",
      TERMS_AND_CONDITIONS: "الأحكام والشروط",
      SHOPPING_CARTS: "عربات التسوق",
      CONTINUE: "متابعة",
      BACK_TO_ORDERS_LIST: "رجوع إلى قائمة الطلبات",
      CREATE_AN_ACCOUNT: "انشاء حساب",
      FORGET_PASSWORD: "اعادة كلمة المرور",
      EDIT_PROFILE: "تعديل الحساب",
      PAGES: "الصفحات",
      LANGUAGE: "اللغة",
      ARABIC: "العربية",
      ENGLISH: "English",
      MY_RFQ: "عروض الاسعار",
      OFFERS: "العروض",
      SERVICES: "الخدمات",
    },
    INPUTS: {
      ALL_CATEGORIES: "جميع الأصناف",
      SHOP_BY_CATEGORY: "تسوق حسب التصنيف",
      SEARCH_PRODUCTS: "البحث في أكثر من 10000 منتج",
      ADD_TO_CART: "إضافة إلى السلة",
      EMAIL: "البريد الإلكتروني",
      PASSWORD: "كلمة المرور",
      BACK_TO_LOGIN: "الرجوع إلى تسجيل الدخول",
      BACK_TO_HOMEPAGE: "الرجوع إلى الصفحة الرئيسية",
      SUBMIT: "تأكيد",
      NAME: "الاسم",
      COMPANY_TENANT: "رابط الشركة",
      COMPANY_NAME: "اسم الشركة",
      PHONE_NUMBER: "رقم الهاتف",
      UNIFIED_PHONE_NUMBER: "رقم الهاتف الموحد",
      COUNTRY: "البلد",
      COUNTRY_CODE: "رمز البلد",
      CONFIRM_PASSWORD: "تأكيد كلمة المرور",
      ADD_COMPANY: "إضافة شركة",
      EDIT: "تعديل",
      VIEW: "عرض",
      CHANGE_LOGO: "تغيير الشعار",
      ENGLISH_COMPANY_NAME: "اسم الشركة بالانجليزية",
      ARABIC_COMPANY_NAME: "اسم الشركة بالعربية",
      COMPANY_EMAIL: "البريد الإلكتروني للشركة",
      COMPANY_DESCRIPTION_EN: "وصف الشركة بالنجليزية",
      COMPANY_DESCRIPTION_AR: "وصف الشركة بالعربية",
      CURRENCY: "العملة",
      PAYMENT_METHODS: "طرق الدفع",
      CITY: "المدينة",
      STREET_NAME: "اسم الشارع",
      BUILDING_NUMBER: "رقم المبنى",
      PHONE: "الهاتف",
      MOBILE: "الموبايل",
      TAX_FILE_NUMBER: "رقم الملف الضريبي",
      MAIN_MAJOR_TYPE: "نوع التخصص الرئيسي",
      SELECT_CATEGORIES: "حدد الفئات",
      ADD_COMPANY_IMAGES: "أضف صور الشركة",
      SEARCH: "بحث",
      SEARCH_CATEGORIES: "البحث عن اصناف",
      DONE: "تم",
      SELECT_YOUR_FILES: "حدد ملفاتك",
      DRAG_AND_DROP_FILES_HERE: "اسحب الملفات و ارمها هنا",
      SAVE: "حفظ",
      CLOSE: "إغلاق",
      SKU: "الكود",
      QUANTITY: "الكمية",
      CATEGORY: "التصنيف",
      SORT_BY: "فرز حسب",
      DEFAULT: "افتراضي",
      NAME_ASC: "الاسم (أ-ي)",
      NAME_DESC: "الاسم (ي-أ)",
      RESET_FILTERS: "افراغ الفلاتر",
      SHOW_ALL_PRODUCTS: "عرض المنتجات",
      SHOW_ALL_SERVICES: "عرض الخدمات",
      PROCEED_TO_CHECKOUT: "تنفيذ الطلب",
      OLACE_ORDER: "تأكيد الطلب",
      CANCLE: "إلغاء",
      REMOVE: "حذف",
      DELETE: "حذف",
      ADD_NEW: "إضافة جديد",
      RECIPIENT_NAME: "اسم المستلم",
      FIRST_NAME: "الاسم الأول",
      LAST_NAME: "اسم العائلة",
      ADDRESS_LINE1: "عنوان الفرع",
      ADDRESS_LINE2: "اسم الفرع",
      POSTAL_CODE: "الرمز البريدي",
      SELECT_LOCATION: "حدد الموقع",
      SEARCH_LOCATION: "ابحث عن الموقع",
      CURRENT_PASSWORD: "كلمة المرور الحالية",
      NEW_PASSWORD: "كلمة المرور الجديدة",
      RE_ENTER_NEW_PASSWORD: "استأجر كلمة المرور الجديدة",
      SHOW: "اظهار",
      CONTINUE: "متابعة",
      CHANGE_PHOTO: "تغيير الصورة",
      REMOVE_PHOTO: "حذف الصورة",
      PRINT: "طباعة",
      SHELF_LIFE_TYPE: "مدة الصلاحية",
      UNIT_OF_MEASURMENT: "وحدة القياسات",
      PACKAGING_TYPE: "طريقة التغليف",
      CAPACITY: "عدد الوحدات لكل ",
      SUBJECT: "العنوان",
      MESSAGE: "الرسالة",
      SEND_MESSAGE: "ارسل الرسالة",
      MINIMUM_ORDER_QUANTITY: "أقل كمية ممكن طلبها",
      REQUEST_FOR_QUOTATION: "طلب عرض سعر",
      WRITE_NOTE_TO_SUPPLIER: "اكتب ملاحظاتك للبائع ...",
      SUPPLIER_NOTES: "ملاحظات المورد",
      YOUR_NOTES: "ملاحظاتك",
      CALCEL_RFQ: "الغاء الطلب",
      SEND_RFQ: "ارسال الطلب",
      CHOOSE_ESTIMATED_DELIVERY_DATE: "اختر تاريخ التوصيل المتوقع",
      CHOOSE_DELIVERY_DATE: "اختر تاريخ التوصيل",
      ESTIMATED_DELIVERY_DATE: "تاريخ التوصيل المتوقع",
      DELIVERY_DATE: "تاريخ التوصيل",
      ORDER_NUMBER: "رقم الطلب",
      ORDER_STATUS: "حالة الطلب",
      CUSTOMER_NAME: "اسم العميل",
      CUSTOMER_EMAIL: "بريد العميل الالكتروني",
      CUSTOMER_PHONE_NUMBER: "رقم هاتف العميل",
      SUB_TOTAL_PRICE: "المجموع الفرعي",
      TAX: "الضريبة",
      TOTAL_DUE_AMOUNT: "السعر الإجمالي المستحق",
      ITEMS_LIST: "المنتجات",
      ITEM_NAME: "اسم المنتج",
      TOTAL_QUANTITIES: "مجموع الكميات",
      LINE_TOTAL: "مجموع السعر شامل الضريبة",
      UNIT_PRICE_DISCOUNT: "السعر بعد الخصم",
      UNIT_PRICE: "سعر الصنف",
      UNIT_PRICE_AFTER_DISCOUNT: "سعر الصنف بعد الخصم",
      LATITUDE: "خط الطول",
      LONGITUDE: "خط العرض",
      DELIVERED: "تم التوصيل",
      EXPORT_PDF: "تحميل PDF",
      ADDRESS_LINE_1: "تفاصيل العنوان 1",
      ADDRESS_LINE_2: "تفاصيل العنوان 2",
      BUYER_COMPANY_NAME: "اسم شركة العميل",
      CREATED_ON: "تاريخ الانشاء",
      CUSTOMER_SHIPPING_ADDRESS: "عنوان الشحن",
      BRANCH: "الفرع",
      NOTES: "ملاحظات",
      FILE_TAX_NUMBER: "رقم الملف الضريبي",
      RFQ_NUMBER: "رقم العرض",
      TAX_VALUE: "قيمة الضريبة",
      VOLUME_CAPACITY: "حجم الوحدة",
      FILTERS: "الفلاتر",
      SEE_MORE: "مشاهدة المزيد",
      REORDER: "اعادة الطلب",
      BACK: "عودة",
    },
    TITLES: {
      TOP_PRODUCTS: "أفضل المنتجات",
      TOP_COMPANIES: "أفضل الشركات",
      SPECIAL_OFFERS: "عروض خاصة",
      POPULAR_CATEGORIES: "التصنيفات الاكثر طلب",
      SOCIAL: "اجتماعي",
      INFORMATION: "المعلومات",
      LOGIN_OR: "تسجيل الدخول أو",
      CREATE_AN_ACCOUNT: "إنشاء حساب",
      SIGNUP: "تسجيل",
      PREVIOUS_DOCUMENTS: "المستندات المحددة السابقة",
      PREVIOUS_IMAGES: "الصور المحددة السابقة",
      SELECT_CATEGORIES: "حدد التصنيفات",
      CROP_IMAGE: "قص الصورة",
      RELATED_PRODUCTS: "منتجات ذات صلة",
      RELATED_SERVICES: "خدمات ذات صلة",
      CATEGORIES: "التصنيفات",
      BRANCHES: "الفروع",
      CART_TOTALS: "اجمالي سلة التسوق",
      SHIPPING_ADDRESS: "عنوان الشحن",
      YOUR_ORDER: "طلبك",
      ORDER_HISTORY: "سجل الطلبات",
      ORDER_NUMBER: "رقم الطلب",
      NAVIGATION: "حسابي",
      DELETE_ADDRESS: "حذف العنوان",
      DELETE_CART: "حذف السلة",
      REMOVE_ITEM: "حذف الصنف",
      DELETE_ORDER: "الغاء الطلب",
      ADD_ADDRESS: "إضافة عنوان",
      EDIT_ADDRESS: "تحرير العنوان",
      MAP: "خريطة",
      CHANGE_PASSWORD: "تغيير كلمة المرور",
      LOGIN_TO_YOUR_ACCOUNT: "قم بتسجيل الدخول",
      MENU: "القائمة",
      OUR_ADDRESSES: "عناويننا",
      PRODUCT_DETAILS: "تفاصيل الصنف",
      SERVICE_DETAILS: "تفاصيل الخدمة",
      DOCUMENTS: "المرفقات",
      NOT_SENT_REQUESTS: "العروض الغير مرسلة",
      SENT_REQUESTS: "العروض المرسلة",
      RESPONSES_REQUESTS: "استلام العروض",
      REJECTED_REQUESTS: "الطبات المرفوضة من المورد",
      ALL_RFQS_REQUESTS: "أرشيف العروض",
      COMPANIES: "الشركات",
    },
    HINTS: {
      FOLLOW_US_ON_SOCIAL: "تابعنا على الشبكات التواصل الاجتماعي",
      RIGHTS_RESERVED: "جميع الحقوق محفوظة © Growthway 2021",
      YOU_CANT_ADD_ITEMS: "لا يمكنك إضافة منتجات إلى سلة التسوق لأنك لم تسجل الدخول , يرجى تسجيل الدخول لإكمال العملية",
      YOU_CANT_OPEN_PAGE: "يجب تسجيل الدخول لكي تتمكن من الدخول لهذه الصفحة",
      FORGET_YOUR_PASSWORD: "نسيت كلمة المرور؟",
      ENTER_YOUR_DETAILS_SIGNUP: "أدخل التفاصيل الخاصة بك لإنشاء حسابك",
      THAKS_FOR_REGISTER: "شكرًا لك على التسجيل",
      I_AGREE_THE: "أوافق على",
      TERMS_AND_CONDITIONS: "الأحكام والشروط",
      UPLOAD_DOCUMENTS: "تحميل المستندات (السجل التجاري)",
      ALLOWED_SIZE_DOCUMENTS: "الحجم المسموح به لكل ملف هو 2 ميجا | التنسيقات المسموح بها هي .doc ، .docx ، .pdf ، .txt",
      THIS_PRICE_INCLUDES_TAX: "هذا السعر يشمل الضريبة",
      SHOWING: "إظهار",
      OF: "من",
      RESULTS: "النتائج",
      NO_MATCHING_ITEMS: "لا توجد منتجات مطابقة لمدخلات البحث",
      NO_MATCHING_SERVICES: "لا توجد خدمات مطابقة لمدخلات البحث",
      NO_MATCHING_COMPANIES: "لا توجد شركات مطابقة لمدخلات البحث",
      TRY_RESETTING_FILTERS: "حاول إعادة تعيين الفلاتر",
      YOUR_CARTS_EMPTY: "سلة التسوق الخاصة بك فارغة!",
      SELECT_SHIPPING_ADDRESS: "حدد عنوان الشحن الخاص بك أو",
      ADD_NEW_SHIPPING_ADDRESS: "إضافة عنوان شحن جديد",
      SELECT_PAYMENT_METHOD: "حدد طريقة الدفع",
      WRITE_NOTE_TO_SELLER: "كتابة ملاحظة للبائع",
      YOU_CANT_CHECKPUT_WITHOUT_ADDRESS: "لا يمكنك الدفع إذا لم يكن لديك أي عنوان شحن.",
      NOTE_TO_BUYER: "ملاحظة للمشتري",
      ORDER_WAS_PLACED_ON: "تم تقديم الطلب في",
      FROM: "من",
      AND_IS_CURRENTLY: "وهو حاليًا",
      NOTES: "ملاحظات",
      DEFAULT: "افتراضي",
      ARE_YOU_SURE_DELETE_ADDRESS: "هل أنت متأكد من أنك تريد حذف هذا العنوان؟",
      ARE_YOU_SURE_DELETE_ORDER: "هل أنت متأكد من أنك تريد الغاء هذا الطلب؟",
      ARE_YOU_SURE_DELETE_CART: "هل أنت متأكد من أنك تريد الغاء هذه السلة",
      MAKE_AS_DEFAULT_ADDRESS: "اجعله عنوانًا افتراضيًا",
      LATITUDE: "خط العرض",
      LONGITUDE: "خط الطول",
      ENTER_EMAIL_TO_RESET_PASSWORD: "ادخل بريدك الالكتروني لاعادة انشاء كلمة السر",
      PASSWORD_LINK_SENT_TO_EMAIL: "تم ارسال رابط اعادة تعيين كلمة المرور على بريدك الاكتروني الرجاء التحقق من وصوله",
      SALE: "عرض",
      YOUR_WISH_LIST_IS_EMPTY: "لا يوجد منتجات مفضلة لديك",
      TO_VERIFY_YOUR_PROFILE: 'يرجى تحميل أوراق الشركة والمستندات الخاصة بك مثل السجل التجاري لمراجعة هذه المستندات من قبل المسؤولين لدينا لجعل حسابك موثق.',
      WAIT_TO_VERIFY_YOUR_PROFILE: 'سيقوم المسؤولون لدينا بفحص المستندات الخاصة بك في أقرب وقت ممكن.',
      PRESS_HERE_TO_BE_VERIFIED: "قم بالضغط هنا لتوثيق حسابك",
      THANK_YOU: "شكرا لك",
      YOUR_ORDER_PLACED: "تم استلام طلبك بنجاح",
      GO_TO_HOMEPAGE: "عودة للصفحة الرئيسية",
      EXPECTED_DELIVERY_DATE: "تاريخ التسليم المتوقع",
      DELIVERY_DATE: "تاريخ التسليم الفعلي",
      LEAVE_US_MESSAGE: "ارسل لنا رسالة",
      COMMING_SOON: "قريبا",
      NO_REQUESTS_FOR_QUOTATIONS: "لم يتم العثور على عروض",
      ARE_YOU_SURE_DELETE_RFQ: "هل انت متأكد من انك تريد الغاء عرض الطلب على الاسعار ؟",
      REMAINING: "سينتهي عرض السعر هذا بعد: ",
      SEEN: "تمت مشاهدته",
      YOUR_NOTIFICATIONS_LIST_IS_EMPTY: "لايوجد لديك اشعارات",
      THIS_PRICE_CHANGED: "هذا السعر تم تغييره من ",
      TO: "الى",
    },
    VALIDATIONS: {
      email_required: "البريد الإلكتروني مطلوب",
      email_email: "البريد الإلكتروني غير صحيح",
      email_minlength: "يجب أن يحتوي البريد الإلكتروني على 6 خانات على الأقل",
      name_required: "الاسم مطلوب",
      countryId_required: "البلد مطلوب",
      phoneNumber_required: "الهاتف مطلوب",
      phoneNumber_pattern: "الهاتف غير صحيح ويجب ألا يحتوي على 0 في البداية ويجب ألا يحتوي على رموز خاصة",
      phoneNumber_maxlength: "الحد الأقصى لعدد الخانات هو 9",
      phoneNumber_minlength: "الحد الأدنى لعدد الخانات هو 9",
      password_required: "كلمة المرور مطلوبة",
      password_minlength: "يجب أن تحتوي كلمة المرور على 6 خانات على الأقل ",
      cPassword_required: "مطلوب تأكيد كلمة المرور",
      cPassword_minlength: "يجب أن تحتوي كلمة المرور المؤكدة على 6 خانات على الأقل",
      cPassword_mustMatch: "كلمة المرور وتأكيد كلمة المرور غير متطابقين.",
      PHOTO_SIZE: " الرجاء تحديد صورة بحجم أقل من 2 ميجا بايت",
      REQUIRED_FIELD: "هذا الحقل مطلوب",
      COMPANY_NAME: "اسم الشركة غير صحيح",
      COMPANY_TENANT_NAME: "رابط الشركة غير صحيح",
      cityId_required: "المدينة مطلوبة",
      oldPassword_required: "كلمة المرور الحالية مطلوبة",
      newPassword_required: "كلمة المرور مطلوبة",
      newPassword_minlength: "يجب أن تحتوي كلمة المرور على 6 خانات على الأقل",
      ConfirmPassword_required: "مطلوب إعادة إدخال كلمة المرور الجديدة",
      ConfirmPassword_minlength: "يجب أن تحتوي إعادة إدخال كلمة المرور الجديدة على 6 خانات على الأقل",
      ConfirmPassword_mustMatch: "كلمة المرور خانة تأكيد كلمة المرور الجديدة غير متطابقتين.",
      THIS_TENANT_IS_ALREADY_EXISTS: "هذا الرابط محجوز لشركة اخرى",
      UNIFIED_PHONE_NUMBER_INVALID: "رقم الهاتف الموحد غير صحيح",
      PHONE_NUMBER_INVALID: "رقم الهاتف غير صحيح",
      MOBILE_NUMBER_INVALID: "رقم الموبايل غير صحيح",
    },
    TABLE: {
      IMAGE: "صورة",
      PRODUCT: "المنتج",
      PRICE: "السعر",
      QUANTITY: "الكمية",
      TOTAL: "الإجمالي",
      SUBTOTAL: "المجموع الفرعي",
      DISCOUNT: "الخصم",
      TAX: "نسبة الضريبة",
      ORDER: "الطلب",
      DATE: "التاريخ",
      COMPANY: "الشركة",
      STATUS: "الحالة",
      ITEM: "عنصر (عناصر)",
      FOR: "لاجل",
      TOTAL_DUE_AMOUNT: 'المجموع الاجمالي شامل ضريبة المبيعات',
      PRICE_AFTER_DISCOUNT: "سعر الصنف بعد الخصم",
    },
    STATIC: {
      KSA: "السعودية",
      KSA_DETAILS1: "الرياض - الواحة - شارع عثمان ابن عفان",
      KSA_DETAILS2: "مبنى رقم 8736 - الطابق الثالث مكتب رقم 18",
      UAE: "الامارات",
      UAE_DETAILS1: "دبي - أبراج بحيرات جميرا - JBC2 - مكتب رقم 1509",
      JORDAN: "الاردن",
      JORDAN_DETAILS1: "عمان - الجبيهة - مجمه المنهل",
      ABOUT_US_MAIN: "جروثواي هي أول منصة أعمال ذكية تربط الموردين بمقدمي خدمات الطعام وأولئك الذين يعملون في نفس المجال حول العالم. نوفر لكل مورد صفحته الخاصة على التطبيق حيث يمكنه إضافة عدد غير محدود من المنتجات مع صورها ووصفها. كما يُسمح له بإدارة العديد من العمليات من خلال التطبيق مثل أوامر الشراء والتسليم وتتبع التسليم وإرسال الإعلانات المستهدفة وتحليل المعلومات من خلال التقارير المهنية والخاصة.",
      ABOUT_US_OUR_VISION: "رؤيتنا",
      ABOUT_US_OUR_VISION_1: "نسعى لنكون أفضل منصة رقمية تربط موردي التجارة الإلكترونية بقطاع الأعمال بسهولة.",
      ABOUT_US_OUR_VISION_2: "نسعى لتغيير شكل صناعة وتجارة المواد الغذائية من أجل نقلها إلكترونيًا.",
      ABOUT_US_FLEXIBILITY: "المرونة",
      ABOUT_US_EFFICIENCY: "الكفاءة",
      ABOUT_US_SPEED: "سرعة الإنجاز",
      ABOUT_US_SUSTAINABLE: "النمو المستدام",
      ABOUT_US_TRANSPARENCY: "الشفافية",
      ABOUT_US_COMMUNICATION: "التواصل",
      ABOUT_US_OUR_MISSION: "مهمتنا",
      ABOUT_US_OUR_MISSION_1: "إنشاء منصة متقدمة تجمع جميع الشركات المتخصصة في صناعة الأغذية لتسهيل الاتصالات التجارية وتسريع الأعمال وتقليل التكاليف من أجل تحقيق النمو المستدام.",
      ABOUT_US_OUR_MISSION_2: "توفير موقع ويب لكل شركة لإدارة عملياتها التسويقية والتشغيلية من خلالها",
      ABOUT_US_OUR_MISSION_3: "تبسيط جميع الخدمات اللوجستية ومنحك الحق في تتبع جميع عملياتك حتى تصل إلى العميل.",
      ABOUT_US_OUR_MISSION_4: "تصميم وحدات الجدوى التجارية الآلية لمساعدة المستثمرين على تقييم الفرص بشكل فعال وتأمين عائد أعلى على معدلات الاستثمار.",
      ABOUT_US_OUR_MISSION_5: "التكامل الرأسي لـ B2B لتعزيز الكفاءة وزيادة الاستفادة من الموظفين والتحكم في تكاليف التشغيل.",
      ABOUT_US_OUR_MISSION_6: "استخدام تقنيات البيانات وإعداد التقارير لإنشاء رؤى تجارية مهمة ، وتقييم الأداء ، والتنبؤ باحتياجات العملاء بالإضافة إلى اتجاهات السوق ولضمان أفضل فرص الاستثمار." ,
      ABOUT_US_OUR_SERVICES: "خدماتنا" ,
      ABOUT_US_OUR_SERVICES_ACCESS: "وصول 360 درجة" ,
      ABOUT_US_OUR_SERVICES_ACCESS_1: "جميع الموردين في متناول يدك" ,
      ABOUT_US_OUR_SERVICES_ACCESS_2: "أفضل سعر" ,
      ABOUT_US_OUR_SERVICES_ACCESS_3: "أفضل موقع" ,
      ABOUT_US_OUR_SERVICES_ACCESS_4: "أفضل خدمة" ,
      ABOUT_US_OUR_SERVICES_ACCESS_5: "مراجعات" ,
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT: "إدارة الطلبات" ,
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT_1: "ضع طلبك" ,
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT_2: "تتبع طلبك" ,
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT_3: "بالكامل وفي الوقت المناسب" ,
      ABOUT_US_OUR_SERVICES_ORDER_MANAGEMENT_4: "لوحات التحكم القابلة للتخصيص" ,
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS: "حلول تكنولوجيا المعلومات" ,
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS_1: "نقاط البيع المخصصة على الويب" ,
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS_2: "أنظمة محاسبية شاملة" ,
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS_3: "أدوات التصفية المتقدمة" ,
      ABOUT_US_OUR_SERVICES_IT_SOLUTIONS_4: "تقارير تحليلات الوقت الفعلي" ,
      ABOUT_US_OUR_SERVICES_MARKET_INSIGHTS: "رؤى السوق" ,
      ABOUT_US_OUR_SERVICES_MARKET_INSIGHTS_1: "العملاء المحتملون المحتملون",
      ABOUT_US_OUR_SERVICES_MARKET_INSIGHTS_2: "اتجاهات السوق حسب القسم" ,
      ABOUT_US_OUR_SERVICES_MARKET_INSIGHTS_3: "تحليل حصة السوق" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION: "تحسين الأعمال" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_1: "توحيد العمليات" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_2: "التحكم في التكلفة" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_3: "التدريب والتطوير" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_4: "عملية رسم الخرائط" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_OPTIMIZATION_5: "مراقبة الجودة" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_PLANNING: "تخطيط الأعمال" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_PLANNING_1: "دراسات جدوى كاملة" ,
      ABOUT_US_OUR_SERVICES_BUSINESS_PLANNING_2: "الخدمات الاستشارية المستندة إلى الاستطلاعات العلمية" ,
      ABOUT_US_DOCUMENTATION: "الوثائق" ,
      ABOUT_US_DOCUMENTATION_1: "نحن في جروث واي نضمن أن جميع الموردين ومقدمي الخدمات على منصة جروث واي موثوق بهم ومعتمدون من خلال التراخيص التجارية ويتم اختيارهم بعناية من قبل فريق التسويق المتخصص لدينا." ,
    },
    LOOKUPS: {
      MINUTES: 'دقيقة',
      HOUR: 'ساعة',
      DAY: 'يوم',
      MONTH: 'شهر',
      YEAR: 'سنة',
      JANUARY: 'يناير',
      FEBRUARY: 'فبراير',
      MARCH: 'مارس',
      APRIL: 'ابريل',
      MAY: 'مايو',
      JUNE: 'يونيو',
      JULY: 'يوليو',
      AUGUST: 'أغسطس',
      SEPTEMBER: 'سبتمبر',
      OCTOBER: 'أكتوبر',
      NOVEMBER: 'نوفمبر',
      DECEMBER: 'ديسمبر',
      Supplier: "مزود منتجات",
      ServiceProvider: "مزود خدمات",
      SupplierAndServiceProvider: "مزود منتجات و مزود خدمات",
      AND: "و",
    }
  }
};
