import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/modules/home/service/home.service';
import { StoreService } from '../../../../shared/services/store.service';
import { FooterService } from '../../footer.service';

export interface SocialLinksItem {
    type: string;
    url: string;
    icon: string;
}

export type SocialLinksShape = 'circle' | 'rounded';

@Component({
    selector: 'app-footer-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

    items: SocialLinksItem[];

    constructor(public store: StoreService,
        private footerService: FooterService) { }

    ngOnInit(): void {
        this.footerContent();
    }

    footerContentModel: any;
    footerContent() {
        this.footerService.footerContent().subscribe((data) => {
            this.footerContentModel = data?.result;
            this.footerService.items = [
                { type: 'facebook', url: this.footerContentModel?.facebookUrl, icon: 'fab fa-facebook-f' },
                { type: 'twitter', url: this.footerContentModel?.twitterUrl, icon: 'fab fa-twitter' },
                { type: 'youtube', url: this.footerContentModel?.youtubeUrl, icon: 'fab fa-youtube' },
                { type: 'instagram', url: this.footerContentModel?.instagramUrl, icon: 'fab fa-instagram' },
                { type: 'linkedin', url: this.footerContentModel?.linkedInUrl, icon: 'fab fa-linkedin-in' },
            ];
        }, (error) => {});
    }



}
