import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { DirectionService } from '../../../shared/services/direction.service';
import { HomeService } from '../../home/service/home.service';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent implements OnInit {
    @Input() withDepartments = false;
    @Input() images:any[] = [];
    slides: any;
    options = {
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        autoplaySpeed: 1000,
        autoplayTimeout: 8000,
        responsive: {
            0: { items: 1 }
        },
        rtl: this.direction.isRTL()
    };

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService,
        private homeService: HomeService,
        private loderService: LoaderService,
    ) { }

    ngOnInit(): void {
        if(this.images.length) {
            this.slides = this.images.map(item=>{return {imageSiteUrl: item.imagePath}});
        }
        else {
            this.getBannerImages();
        }
    }

    getBannerImages() {
        this.loderService.setIsLoading = true;
        this.homeService.getBannerImages().subscribe((data) => {
            this.slides = data.result?.bannerImagesItems?.items;
            this.loderService.setIsLoading = false;
        }, (error) => { 
            this.loderService.setIsLoading = false;
        });
    }
}